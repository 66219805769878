import React from 'react';
import { Form, Button, Row, Col, Container } from 'react-bootstrap';
import { FaEdit } from 'react-icons/fa'; 

function AdminPannelUserProfilePage() {
  return (
    <Container className="d-flex justify-content-center align-items-center min-vh-100">
      <div className="create-account-container">
        
 <div className="d-flex align-items-center mb-4">
          <h2 className="profile-header">Profile</h2>
          <FaEdit className="edit-icon ms-3" /> {/* Edit icon with spacing */}
        </div>
        <Form className="account-form">
          <Row>
            <Col md={6}>
              <Form.Group controlId="firstName">
                <Form.Label className="form-label">First Name</Form.Label>
                <Form.Control type="text" placeholder="Enter your first name" />
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group controlId="lastName">
                <Form.Label className="form-label">Last Name</Form.Label>
                <Form.Control type="text" placeholder="Enter your last name" />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <Form.Group controlId="email">
                <Form.Label className="form-label">Email</Form.Label>
                <Form.Control type="email" placeholder="Enter your email" />
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group controlId="password">
                <Form.Label className="form-label">Password</Form.Label>
                <Form.Control type="password" placeholder="Enter your password" />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <Form.Group controlId="address">
                <Form.Label className="form-label">Address</Form.Label>
                <Form.Control type="text" placeholder="Enter your address" />
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group controlId="country">
                <Form.Label className="form-label">Country</Form.Label>
                <Form.Control type="text" placeholder="Enter your country" />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <Form.Group controlId="nationality">
                <Form.Label className="form-label">Nationality</Form.Label>
                <Form.Control type="text" placeholder="Enter your nationality" />
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group controlId="investmentRange">
                <Form.Label className="form-label">Investment Range</Form.Label>
                <Form.Control as="select">
                  <option>999 - 3000 $</option>
                  <option>3001 - 5000 $</option>
                  <option>5001 - 10000 $</option>
                </Form.Control>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <Form.Group controlId="category">
                <Form.Label className="form-label">Category</Form.Label>
                <Form.Control as="select">
                  <option>Technology</option>
                  <option>Finance</option>
                  <option>Health</option>
                </Form.Control>
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group controlId="niche">
                <Form.Label className="form-label">Niche</Form.Label>
                <Form.Control as="select">
                  <option>AI</option>
                  <option>Blockchain</option>
                  <option>Data Science</option>
                </Form.Control>
              </Form.Group>
            </Col>
          </Row>
          <Button href='/' variant="primary" type="submit" className="submit-btn">
            Save
          </Button>
          
        </Form>

        <style jsx>{`
          .signup-link {
            color: #c69e59;
            text-decoration: none;
            font-weight: bold;
          }

          .signup-link:hover {
            text-decoration: underline;
          }
          .create-account-container {
            width: 100%;
            max-width: 800px; /* Increased card size */
            padding: 2rem;
            background-color: #ffffff;
            border-radius: 15px;
            box-shadow: 0 6px 18px rgba(0, 0, 0, 0.1);
            margin-bottom: 60px;
            margin-left:240px;
          }

          @media (max-width: 992px) {
            .create-account-container {
              margin-left: 0;
              padding-top: 20px;
            }
            .admin-panel .table-container {
              padding: 10px;
            }
          }
          .account-form {
            margin-top: 1rem;
          }
          .form-label {
            display: block; /* Ensure label starts at the same line as input */
            font-weight: 600;
            margin-bottom: 0.3rem;
            color: #333;
            text-align: left; /* Ensure label is aligned left */
          }
          .submit-btn {
            width: 30%; /* Adjusted button width to 20% */
            background-color: #c69e59;
            border-radius: 30px;
            border:none;
            padding: 0.75rem;
            font-weight: bold;
            font-size: 1rem;
            margin-top: 1rem;
            margin-left: auto; /* Center the button */
            margin-right: auto; /* Center the button */
            display: block; /* Make button a block element */
          }
          .edit-icon {
            font-size: 1.2rem;
            color: #c69e59;
            cursor: pointer;
          }

          .edit-icon:hover {
            color: #b07e46;
          }
          .submit-btn:hover {
            background-color: #b07e46;
          }
          .login-link {
            color: #007bff;
            font-weight: 500;
            text-decoration: none;
          }
          .login-link:hover {
            text-decoration: underline;
          }
          /* Reducing space between checkbox and label */
          .checkbox-group .form-check-input {
            margin-right: 1rem; /* Reduced space between checkbox and label */
          }
        `}</style>
      </div>
    </Container>
  );
}

export default AdminPannelUserProfilePage;
