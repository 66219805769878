import { auth } from '../firebaseConfig'; 

class UserService {
    static getCurrentUserId() {
        const user = auth.currentUser;
        if (user) {
            return user.uid;
        } else {
            throw new Error("No user is logged in.");
        }
    }

    static isUserLoggedIn() {
        return auth.currentUser !== null;
    }
}

export default UserService;

