import { fetchCollectionData } from "../../services/retriveDataService"; // Adjust path

export const fetchIdea = async () => {
  try {
    const ideas = await fetchCollectionData("ideas");
    return ideas;
  } catch (error) {
    console.error("Error fetching ideas:", error);
    throw new Error("Failed to fetch ideas. Please check the console for more details.");
  }
};
