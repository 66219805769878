import { useState } from "react";
import { Container, Form, Button, Card, Row, Col } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import ConfirmationPopUp from "../../components/pop_up/confirmation";
import { useLocation } from "react-router-dom";
import { doc, setDoc, updateDoc, query, where, getDocs, collection } from "firebase/firestore";
import { db } from "../../firebaseConfig";
import UserService from "../../controllers/currentUserId";

const PaymentConfirmationPage = () => {
  const location = useLocation();
  const { idea } = location.state || {};

  const [showPopup, setShowPopup] = useState(false);
  const [paymentMethod, setPaymentMethod] = useState("");
  const [cardHolderName, setCardHolderName] = useState("");
  const [cardNumber, setCardNumber] = useState("");
  const [expiryDate, setExpiryDate] = useState("");
  const [isValid, setIsValid] = useState(false);
  const [buttonText, setButtonText] = useState("Confirm Payment");

  const isWonIdea = idea.status === "Won Idea";

  // Validation functions
  const validateForm = () => {
    const cardNumberValid = /^\d{14}$/.test(cardNumber);
    const cardHolderNameValid = /^[A-Za-z\s]+$/.test(cardHolderName);
    const expiryDateValid = expiryDate !== "";

    setIsValid(cardNumberValid && cardHolderNameValid && expiryDateValid);
  };

  const handleShow = async (e) => {
    e.preventDefault(); // Prevent default form submission behavior
    setButtonText("Processing..."); // Change button text to "Processing..."

    try {
      const userId = UserService.getCurrentUserId();

      if (!userId || !idea) {
        throw new Error("User ID or Idea is missing.");
      }

      // Save payment details to Firestore
      const transactionRef = doc(db, "transactions", `${idea.id}-${userId}`);
      await setDoc(transactionRef, {
        userId: userId,
        ideaId: idea.id,
        ideaTitle: idea.title,
        amount: 100, // Fixed payment amount
        paymentMethod: paymentMethod,
        cardHolderName: cardHolderName,
        timestamp: new Date(),
      });

      // Update idea status in Firestore
      const ideaRef = doc(db, "ideas", idea.id);
      await updateDoc(ideaRef, {
        status: "Won Idea",
      });

      // Locate and update the specific document in user_idea_statuses
      const userIdeaStatusesQuery = query(
        collection(db, "user_idea_statuses"),
        where("userId", "==", userId),
        where("ideaId", "==", idea.id)
      );
      const querySnapshot = await getDocs(userIdeaStatusesQuery);

      if (!querySnapshot.empty) {
        const userIdeaDocRef = querySnapshot.docs[0].ref; // Assuming only one match
        await updateDoc(userIdeaDocRef, {
          status: "Won Idea",
        });
      } else {
        console.warn("No matching document found in user_idea_statuses.");
      }

      setButtonText("Confirm Payment"); // Reset button text
      setShowPopup(true); // Show the confirmation pop-up
    } catch (error) {
      console.error("Error processing payment:", error.message);
      alert("An error occurred while processing the payment.");
      setButtonText("Confirm Payment"); // Reset button text on error
    }
  };

  const handleClose = () => setShowPopup(false);

  return (
    <Container className="py-5">
      <style>
        {`
          .container-wrapper {
            max-width: 600px;
            margin: 0 auto;
          }

          .payment-header {
            font-size: 2rem;
            font-weight: bold;
            color: #333;
            text-align: center;
            margin-bottom: 30px;
          }

          .top-row {
            background: #f8f3e3;
            padding: 15px 20px;
            border-radius: 10px;
            margin-bottom: 25px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            font-size: 1.2rem;
            font-weight: 600;
            color: #444;
            box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
          }

          .fee-amount {
            color: #c69e59;
            font-size: 1.5rem;
            font-weight: bold;
          }

          .payment-card {
            background: #fff;
            padding: 30px;
            border-radius: 12px;
            box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
            margin: 0 auto;
          }

          .payment-option {
            display: flex;
            align-items: center;
            margin-bottom: 20px;
          }

          .payment-option img {
            width: 40px;
            height: 40px;
            border-radius: 6px;
            margin-right: 15px;
          }

          .payment-option label {
            font-size: 1.1rem;
            font-weight: 500;
            color: #333;
            margin-bottom: 0;
            flex-grow: 1;
            text-align: start;
          }

          .payment-input {
            padding: 10px 15px;
            border-radius: 8px;
            border: 1px solid #ddd;
            width: 60%;
          }

          .submit-btn {
            background-color: #c69e59;
            color: white;
            border: none;
            border-radius: 30px;
            padding: 10px;
            font-size: 1rem;
            font-weight: bold;
            width: 220px;
            display: block;
            margin: 30px auto 0 auto;
            box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
          }

          .submit-btn:hover {
            background-color: #b08445;
          }

          .submit-btn:disabled {
            background-color: #ddd;
            color: #aaa;
            cursor: not-allowed;
          }
        `}
      </style>
      <div className="container-wrapper">
        {/* Payment Header */}
        <h1 className="payment-header">Payment Method</h1>
        {/* Top Row */}
        <Row className="top-row">
          <Col className="text-start">
            <span className="fee-amount">100 $</span>
          </Col>
          <Col className="text-end">{idea.title}</Col>
        </Row>
        {/* Payment Form */}
        <Card className="payment-card">
          <Form>
            <div className="payment-option">
              <img src="/images/slider.png" alt="Credit Card" />
              <Form.Check
                type="radio"
                name="paymentMethod"
                id="creditCard"
                className="me-2"
                disabled={isWonIdea}
                onChange={() => setPaymentMethod("Credit Card")}
              />
              <label htmlFor="creditCard">Credit Card</label>
              <Form.Control
                type="text"
                placeholder="Card Holder Name"
                className="payment-input"
                disabled={isWonIdea}
                onChange={(e) => {
                  setCardHolderName(e.target.value);
                  validateForm();
                }}
              />
            </div>

            <div className="payment-option">
              <img src="/images/slider.png" alt="PayPal" />
              <Form.Check
                type="radio"
                name="paymentMethod"
                id="paypal"
                className="me-2"
                disabled={isWonIdea}
                onChange={() => setPaymentMethod("PayPal")}
              />
              <label htmlFor="paypal">PayPal</label>
              <Form.Control
                type="text"
                placeholder="Card Number"
                className="payment-input"
                disabled={isWonIdea}
                onChange={(e) => {
                  setCardNumber(e.target.value);
                  validateForm();
                }}
              />
            </div>

            <div className="payment-option">
              <img src="/images/slider.png" alt="Google Pay" />
              <Form.Check
                type="radio"
                name="paymentMethod"
                id="googlePay"
                className="me-2"
                disabled={isWonIdea}
                onChange={() => setPaymentMethod("Google Pay")}
              />
              <label htmlFor="googlePay">Google Pay</label>
              <Form.Control
                type="date"
                className="payment-input"
                disabled={isWonIdea}
                onChange={(e) => {
                  setExpiryDate(e.target.value);
                  validateForm();
                }}
              />
            </div>

            <Button
              type="submit"
              onClick={handleShow}
              className="submit-btn"
              disabled={!isValid || isWonIdea}
            >
              {buttonText}
            </Button>
            <ConfirmationPopUp show={showPopup} handleClose={handleClose} />
          </Form>
        </Card>
      </div>
    </Container>
  );
};

export default PaymentConfirmationPage;
