import React, { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import { Offcanvas, Button } from 'react-bootstrap';
import { FaBars } from 'react-icons/fa';

const AdminPannelSidebar = () => {
  const [showSidebar, setShowSidebar] = useState(window.innerWidth >= 1000);

  const handleClose = () => setShowSidebar(false);
  const handleShow = () => setShowSidebar(true);

  useEffect(() => {
    const handleResize = () => setShowSidebar(window.innerWidth >= 1000);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const sidebarLinks = [
    { path: '/admin', label: 'Dashboard' },
    { path: '/admin/userManagment', label: 'User Management' },
    { path: '/admin/ideaManagment', label: 'Idea Management' },
    { path: '/admin/settings', label: 'Settings' },
  ];

  const linkStyle = {
    color: '#333',
    textDecoration: 'none',
    padding: '10px 15px',
    borderRadius: '5px',
    fontSize: '16px',
    fontWeight: '500',
    display: 'block',
  };

  const activeLinkStyle = {
    backgroundColor: '#c69e59',
    color: '#fff',
  };

  const SidebarContent = () => (
    <div className="d-flex flex-column gap-3">
      <div className="text-center font-weight-bold mb-4" style={{
        fontWeight: 'bold',
        color: 'black',
        fontSize: '1.2rem',
      }}>
        TL <span style={{
          color: '#c69e59',
          fontSize: '1.5rem',
        }}>Interprise</span>
      </div>
      {sidebarLinks.map((link) => (
        <NavLink
          key={link.path}
          to={link.path}
          style={({ isActive }) => (isActive ? { ...linkStyle, ...activeLinkStyle } : linkStyle)}
          onClick={() => setShowSidebar(window.innerWidth >= 1000)}
        >
          {link.label}
        </NavLink>
      ))}
    </div>
  );

  return (
    <>
      {/* Navbar Button for Small Screens */}
      <div className="d-lg-none" style={{ position: 'fixed', top: 10, left: 10, zIndex: 1050 }}>
        <Button variant="link" onClick={handleShow} style={{ padding: 0 }}>
          <FaBars size={24} color="#333" />
        </Button>
      </div>

      {/* Sidebar for Large Screens */}
      {showSidebar && (
        <div
          className="d-none d-lg-flex flex-column"
          style={{
            width: '220px',
            height: '100vh',
            backgroundColor: 'white',
            boxShadow: '2px 0 5px rgba(0, 0, 0, 0.1)',
            padding: '20px',
            borderRight: '1px solid #e6e6e6',
            position: 'fixed',
            top: 0,
            left: 0,
            overflowY: 'auto',
            zIndex: 1000,
          }}
        >
          <SidebarContent />
        </div>
      )}

      {/* Offcanvas for Small Screens */}
      <Offcanvas show={showSidebar && window.innerWidth < 1000} onHide={handleClose}>
        <Offcanvas.Header closeButton>
          <Offcanvas.Title style={{ fontWeight: 'bold', color: '#FF6600', fontSize: '1.5rem' }}>
            TL <span style={{
              fontWeight: 'bold',
              color: 'black',
              fontSize: '1.2rem',
            }}>Interprise</span>
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <SidebarContent />
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

export default AdminPannelSidebar;
