import { db, storage } from "../../firebaseConfig"; // Adjust the path as needed
import { collection, addDoc } from "firebase/firestore";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";

export const submitIdea = async (formData, file) => {
  try {
    let imageUrl = 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR9SRRmhH4X5N2e4QalcoxVbzYsD44C-sQv-w&s';

    // Upload image if provided
    if (file) {
      const storageRef = ref(storage, `ideas/${file.name}`);
      const snapshot = await uploadBytes(storageRef, file);
      imageUrl = await getDownloadURL(snapshot.ref);
    }

    // Add form data along with image URL to Firestore
    const docRef = await addDoc(collection(db, "ideas"), {
      title: formData.title,
      location: formData.location,
      description: formData.description,
      longDescription: formData.longDescription,
      category: formData.category,
      niche: formData.niche,
      status:'Pending',
      type: formData.type,
      ideaImage: imageUrl,
      createdAt: new Date().toISOString(),
    });

    console.log("Idea successfully submitted with ID: ", docRef.id);
    return { success: true, id: docRef.id };
  } catch (error) {
    console.error("Error submitting idea: ", error);
    return { success: false, error };
  }
};
