import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage"; 

const firebaseConfig = {
  apiKey: "AIzaSyDHU9qLhYfY9rXvKxsjJOJTHJ95e0A--fA",
  authDomain: "tl-enterprise-6a19b.firebaseapp.com",
  projectId: "tl-enterprise-6a19b",
  storageBucket: "tl-enterprise-6a19b.firebasestorage.app",
  messagingSenderId: "506837205457",
  appId: "1:506837205457:web:677d1232f9de94316ce0f0",
  // measurementId: "G-H6LW1MBKW4"
};

const app = initializeApp(firebaseConfig);

export const auth = getAuth(app);
export const db = getFirestore(app);
export const storage = getStorage(app);

export default app;
