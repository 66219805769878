import React from "react";
import Hero from "../components/home/hero";
import Slider from "../components/home/slider";
import Onboarding from "../components/home/onboarding";
import FrequentlyQuestions from "../components/home/frequentlyQuestions";

function Home() {
  return (
    <div>
      <Hero />
      <Slider/>
      <Onboarding/>
      <FrequentlyQuestions/>
    </div>
  );
}


export default Home;
