import React from "react";
import AdminPannelMainHero from "../../components/admin-pannel/mainHero";

function MainAdminPannelPage() {
  return (
    <div>
    <AdminPannelMainHero/>
    </div>
  );
}


export default MainAdminPannelPage;
