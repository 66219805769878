import React, { useState, useEffect } from "react";
import { Table, Button, Form, Modal, Spinner } from "react-bootstrap";
import { FaTrashAlt } from "react-icons/fa";
import { collection, addDoc, onSnapshot, deleteDoc, doc } from "firebase/firestore";
import { db } from "../../firebaseConfig"; // Adjust the import path

const NicheSettings = () => {
  const [showFilterPopup, setShowFilterPopup] = useState(false);
  const [niches, setNiches] = useState([]);
  const [loading, setLoading] = useState(true);
  const [newNiche, setNewNiche] = useState("");
  const [newCategory, setNewCategory] = useState("");

  const handleShowFilterPopup = () => setShowFilterPopup(true);
  const handleCloseFilterPopup = () => {
    setNewNiche("");
    setNewCategory("");
    setShowFilterPopup(false);
  };

  const handleAddNiche = async () => {
    if (newNiche.trim() && newCategory.trim()) {
      try {
        await addDoc(collection(db, "niches"), { niche: newNiche, category: newCategory });
        handleCloseFilterPopup();
      } catch (error) {
        console.error("Error adding niche: ", error);
      }
    }
  };

  const handleDeleteNiche = async (id) => {
    try {
      await deleteDoc(doc(db, "niches", id));
    } catch (error) {
      console.error("Error deleting niche: ", error);
    }
  };

  useEffect(() => {
    const unsubscribe = onSnapshot(
      collection(db, "niches"),
      (snapshot) => {
        const fetchedNiches = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setNiches(fetchedNiches);
        setLoading(false);
      },
      (error) => {
        console.error("Error fetching niches: ", error);
        setLoading(false);
      }
    );

    return () => unsubscribe();
  }, []);

  return (
    <>
      <style type="text/css">
        {`
          .category-settings-container {
            max-width: 800px;
            margin: 0 auto;
            padding: 20px;
            margin-top: 30px;
            background: #fff;
            border-radius: 10px;
            box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
          }
          @media (min-width: 992px) {
            .category-settings-container {
              margin-left: 242px;
              padding-right: 20px;
            }
          }
          @media (max-width: 991px) {
            .category-settings-container {
              padding-left: 8px;
              padding-right: 8px;
            }
          }
          .settings-title {
            font-size: 40px;
            font-weight: bold;
            margin-bottom: 30px;
            color: #000;
          }
          .category-header {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 20px;
          }
          .category-title {
            font-size: 24px;
            font-weight: 700;
            color: #000;
          }
          .add-category-btn {
            background-color: #d6a63e;
            color: #fff;
            border: none;
            border-radius: 30px;
          }
          .add-category-btn:hover {
            background-color: #c59532;
          }
          .category-table {
            width: 100%;
          }
          .category-table th {
            font-weight: 600;
            color: #000;
          }
          .category-table td {
            vertical-align: middle;
          }
          .delete-btn {
            background: none;
            border: none;
            color: #555;
          }
          .delete-btn:hover {
            color: #d9534f;
          }
          .icon-trash-alt {
            color: #d6a63e;
            margin-right: 8px;
            cursor: pointer;
          }
          .filter-popup {
            padding: 20px;
            background-color: #fff;
            border-radius: 10px;
            text-align: center;
          }
          .filter-popup h4 {
            font-size: 24px;
            font-weight: bold;
            margin-bottom: 20px;
          }
          .filter-popup .form-control {
            border-radius: 30px;
            padding: 10px 15px;
            border: 1px solid #ccc;
            font-size: 16px;
          }
          .filter-popup .save-button {
            background-color: #d6a63e;
            color: #fff;
            border: none;
            border-radius: 30px;
            padding: 10px 30px;
            font-size: 16px;
            font-weight: bold;
            margin-top: 20px;
          }
          .filter-popup .save-button:hover {
            background-color: #c59532;
          }
          
        `}
      </style>

      <div className="category-settings-container">
        <div className="category-header">
          <h2 className="category-title">Niche & Category</h2>
          <Button className="add-category-btn" onClick={handleShowFilterPopup}>
            Add Niche
          </Button>
        </div>

        {loading ? (
          <div className="loading-text">
            <Spinner animation="border" /> Loading...
          </div>
        ) : (
          <Table bordered hover className="category-table">
            <thead>
              <tr>
                <th>
                  <Form.Check type="checkbox" />
                </th>
                <th>Niche Name</th>
                <th>Category Name</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {niches.map((niche) => (
                <tr key={niche.id}>
                  <td>
                    <Form.Check type="checkbox" />
                  </td>
                  <td>{niche.niche}</td>
                  <td>{niche.category}</td>
                  <td>
                    <FaTrashAlt
                      className="icon-trash-alt"
                      onClick={() => handleDeleteNiche(niche.id)}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        )}
      </div>

      <Modal show={showFilterPopup} onHide={handleCloseFilterPopup} centered>
        <Modal.Body>
          <div className="filter-popup">
            <h4>Niche & Category</h4>
            <Form>
              <Form.Group className="mb-3">
                <Form.Control
                  type="text"
                  placeholder="Niche"
                  className="form-control"
                  value={newNiche}
                  onChange={(e) => setNewNiche(e.target.value)}
                />
                <Form.Control
                  type="text"
                  placeholder="Category"
                  className="mt-4 form-control"
                  value={newCategory}
                  onChange={(e) => setNewCategory(e.target.value)}
                />
              </Form.Group>
              <Button className="save-button" onClick={handleAddNiche}>
                Save
              </Button>
            </Form>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default NicheSettings;
