import { fetchCollectionData } from "../../services/retriveDataService"; 

export const fetchUsers = async () => {
  try {
    const users = await fetchCollectionData("users");
    return users;
  } catch (error) {
    console.error("Error fetching users:", error);
    throw new Error("Failed to fetch users. Please check the console for more details.");
  }
};
