import React from "react";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";

const WritePitchPage = () => {
  return (
    <Container className="py-5">
      <style>
        {`
          .pitch-header {
            font-size: 2rem;
            font-weight: bold;
            color: #333;
            margin-bottom: 30px;
            text-align: center;
          }

          .pitch-card {
            background: #fff;
            padding: 40px;
            border-radius: 12px;
            box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
            max-width: 700px;
            margin: 0 auto;
          }

          .form-label {
            font-size: 1.1rem;
            font-weight: 600;
            color: #444;
            margin-bottom: 8px;
          }

          .form-control {
            border-radius: 10px;
            border: 1px solid #ccc;
            padding: 12px 15px;
            font-size: 1rem;
            width: 100%;
          }

          .form-control:focus {
            border-color: #c69e59;
            box-shadow: 0 0 5px rgba(198, 158, 89, 0.5);
          }

          .submit-btn {
            background-color: #c69e59;
            color: white;
            border: none;
            border-radius: 30px;
            padding: 12px;
            font-size: 1.1rem;
            font-weight: 600;
            width: 180px;
            transition: all 0.3s ease;
          }

          .submit-btn:hover {
            background-color: #b08445;
            transform: translateY(-2px);
          }

          .form-section {
            margin-bottom: 20px;
          }

          @media (max-width: 768px) {
            .pitch-header {
              font-size: 1.8rem;
            }

            .pitch-card {
              padding: 30px;
            }

            .submit-btn {
              width: 100%;
            }
          }
        `}
      </style>

      <h1 className="pitch-header">Write Your Pitch</h1>
      <div className="pitch-card">
        <Form>

          {/* Pitch/Motivation Textarea */}
          <Row className="form-section">
            <Col xs={12} className="text-start">
              <Form.Group controlId="pitchMotivation">
                <Form.Label className="form-label">Pitch / Motivation</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={5}
                  placeholder="Describe your pitch or motivation"
                  className="form-control"
                />
              </Form.Group>
            </Col>
          </Row>

          {/* Submit Button */}
          <Row className="text-center">
            <Col>
              <Button type="submit" className="submit-btn">
                Send Request
              </Button>
            </Col>
          </Row>
        </Form>
      </div>
    </Container>
  );
};

export default WritePitchPage;
