import React, { useState, useEffect } from "react";
import { Table, Button, Badge, Form, Stack, Modal,Spinner } from "react-bootstrap";
import { FaFilter, FaInfoCircle, FaUserCircle } from "react-icons/fa";
import "bootstrap/dist/css/bootstrap.min.css";
import { fetchUsers } from "../../controllers/admin_pannel/userManagmentController"; // Adjust the path as necessary

const AdminPannelUserManagement = () => {
  const [showFilterModal, setShowFilterModal] = useState(false);
  const [filterText, setFilterText] = useState("");
  const [additionalFilterText] = useState(""); // state for the second text field
  const [niche, setNiche] = useState(""); // state for the niche dropdown
  const [users, setUsers] = useState([]); // All users
  const [filteredUsers, setFilteredUsers] = useState([]); // Filtered users
  const [loading, setLoading] = useState(true); // Loading state

  // Fetch users on component mount
  useEffect(() => {
    const loadUsers = async () => {
      setLoading(true); // Set loading to true before fetching
      try {
        const fetchedUsers = await fetchUsers();
        setUsers(fetchedUsers);
        setFilteredUsers(fetchedUsers); // Initialize filtered users
      } catch (error) {
        console.error("Error fetching users:", error);
      } finally {
        setLoading(false); // Set loading to false after fetching
      }
    };

    loadUsers();
  }, []);

  const handleApplyFilter = () => {
    console.log("Filter applied:", filterText, additionalFilterText, niche);
    const filtered = users.filter((user) =>
      user.name.toLowerCase().includes(filterText.toLowerCase()) &&
      user.email.toLowerCase().includes(additionalFilterText.toLowerCase()) &&
      (niche ? user.role?.toLowerCase().includes(niche.toLowerCase()) : true) // Filter by niche if selected
    );
    setFilteredUsers(filtered);
    setShowFilterModal(false); // Close the modal after applying filter
  };

  return (
    <div className="admin-panel pt-4">
      <style>
        {`
          .admin-panel .container {
            padding: 20px;
            padding-left: 250px;
            min-height: 80vh;
          }

          .table-container {
            background-color: #ffffff;
            border-radius: 8px;
            box-shadow: 0px 5px 15px rgba(0, 91, 187, 0.3);
            overflow-x: auto;
            padding: 20px;
          }

          .avatar {
            width: 32px;
            height: 32px;
            border-radius: 50%;
            margin-right: 10px;
          }

          .status-badge {
            padding: 6px 12px;
            border-radius: 12px;
            font-size: 0.85rem;
          }

          /* The filter popup design */
          .filter-popup {
            padding: 20px;
            background-color: #fff;
            border-radius: 10px;
            text-align: center;
          }
          .filter-popup h4 {
            font-size: 24px;
            font-weight: bold;
            margin-bottom: 20px;
          }
          .filter-popup .form-control {
            border-radius: 30px;
            padding: 10px 15px;
            border: 1px solid #ccc;
            font-size: 16px;
          }
          .filter-popup .save-button {
            background-color: #d6a63e;
            color: #fff;
            border: none;
            border-radius: 30px;
            padding: 10px 30px;
            font-size: 16px;
            font-weight: bold;
            margin-top: 20px;
          }
          .filter-popup .save-button:hover {
            background-color: #c59532;
          }

          .custom-button {
            font-size: 0.85rem;
            color: #ffffff;
            background-color: #c69e59;
            border: none;
            padding: 8px 16px;
            border-radius: 8px;
            cursor: pointer;
            transition: background-color 0.3s, box-shadow 0.3s;
            box-shadow: 0px 2px 1px #c69e59;
          }

          .custom-button:hover {
            background-color: #c69e59;
            box-shadow: 0px 2px 5px #c69e59;
          }

          @media (max-width: 992px) {
            .admin-panel .container {
              padding-left: 0;
              padding-top: 20px;
            }
            .admin-panel .table-container {
              padding: 10px;
            }
          }
          .page-title {
            font-size: 40px;
            font-weight: bold;
            margin-bottom: 30px;
            color: #000;
          }
        `}
      </style>

      <div className="idea-management-container">
        <h1 className="page-title">Idea Management</h1>
        <div className="container">
          <div className="actions-bar d-flex align-items-center mb-3">
            <Button className="custom-button" onClick={() => setShowFilterModal(true)}>
              <FaFilter /> Filters
            </Button>
          </div>

          <div className="table-container">
            {loading ? (
              <div className="loading-text">
              <Spinner animation="border" /> Loading...
            </div>
            ) : (
              <Table responsive hover>
                <thead>
                  <tr>
                    <th>
                      <Form.Check type="checkbox" />
                    </th>
                    <th>Name</th>
                    <th>Email</th>
                    <th>Country</th>
                    <th>Status</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {filteredUsers.map((user) => (
                    <tr key={user.id}>
                      <td>
                        <Form.Check type="checkbox" />
                      </td>
                      <td>
                        <Stack direction="horizontal" gap={2} className="align-items-center">
                          {user.profileImageUrl ? (
                            <img
                              src={user.profileImage}
                              alt="avatar"
                              className="avatar"
                            />
                          ) : (
                            <FaUserCircle size={32} color="#666" className="avatar" />
                          )}
                          <span>{user.firstName || "N/A"}</span>
                        </Stack>
                      </td>
                      <td>{user.email || "N/A"}</td>
                      <td>{user.country || "N/A"}</td>
                      <td>
                        <Badge
                          className="status-badge"
                          bg={user.status === "Won Idea" ? "success" : "secondary"}
                        >
                          {user.status || "To Be Discuss"}
                        </Badge>
                      </td>
                      <td>
                        <Button href="/admin/userProfile" variant="link" className="p-0 mx-2">
                          <FaInfoCircle href="/admin/userProfile" size={18} color="#c69e59" />
                        </Button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            )}

            {/* Pagination Buttons */}
            <div className="d-flex justify-content-between align-items-center mt-3">
              <Button variant="outline-secondary" size="sm">
                Previous
              </Button>
              <span>Page 1 of 10</span>
              <Button variant="outline-secondary" size="sm">
                Next
              </Button>
            </div>
          </div>
        </div>
      </div>

      {/* Filter Modal */}
      <Modal show={showFilterModal} onHide={() => setShowFilterModal(false)} centered>
        <Modal.Header closeButton>
          <Modal.Title>Filter</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="filter-popup">
            <h4>Enter Filter Text</h4>
            <Form.Control
              type="text"
              placeholder="Type your filter by Name"
              value={filterText}
              onChange={(e) => setFilterText(e.target.value)}
            />
            <Form.Group className="mb-3" style={{ marginTop: "15px" }}>
              <Form.Label>Niche</Form.Label>
              <Form.Select
                value={niche}
                onChange={(e) => setNiche(e.target.value)}
              >
                <option value="">Select Niche</option>
                <option value="AI">AI</option>
                <option value="Blockchain">Blockchain</option>
                <option value="IoT">IoT</option>
              </Form.Select>
            </Form.Group>
            <Button
              className="save-button"
              onClick={handleApplyFilter}
              style={{ marginTop: "20px" }}
            >
              Apply Filter
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default AdminPannelUserManagement;
