import React, { useState, useEffect } from "react";
import { Container, Navbar, Nav } from "react-bootstrap";
import { FaBell } from "react-icons/fa";
import { BsPerson } from "react-icons/bs";
import { Link, useNavigate } from "react-router-dom";
import { fetchUserProfile } from "../controllers/fetchUserData";
import { auth } from "../firebaseConfig";
import { signOutUser } from '../controllers/signOutController';

const DashboardHeader = () => {
  const [userId, setUserId] = useState(null);
  const [profileImageUrl, setProfileImageUrl] = useState(null);
  const [isImageLoading, setIsImageLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async (user) => {
      if (user) {
        setUserId(user.uid);
        try {
          const userData = await fetchUserProfile(user.uid);
          if (userData && userData.profileImage) {
            setProfileImageUrl(userData.profileImage);
          }
        } catch (error) {
          console.error("Error fetching user profile:", error);
        } finally {
          setIsImageLoading(false);
        }
      } else {
        setUserId(null);
        setProfileImageUrl(null);
        setIsImageLoading(false);
      }
    });

    return () => unsubscribe(); // Cleanup subscription on unmount
  }, []);

  const handleSignOut = async () => {
    try {
      await signOutUser();
      alert('Signed out successfully');
      navigate('/login');
    } catch (error) {
      alert(error.message);
    }
  };

  return (
    <header>
      <style>{`
        .navbar-custom {
          background-color: #f8f3e3;
          padding: 10px 0;
          box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
          position: sticky;
          top: 0;
          z-index: 1000;
        }

        .brand-container {
          display: flex;
          align-items: center;
          flex: 0 0 50%;
        }

        .brand-text-tl {
          font-weight: bold;
          color: #000000;
          font-size: 1.5rem;
          margin-right: 5px;
        }

        .brand-text-institute {
          font-weight: 400;
          color: #000000;
          font-size: 1.1rem;
        }

        .nav-container {
          display: flex;
          align-items: center;
          justify-content: flex-end;
          flex: 0 0 90%;
          gap: 20px;
        }

        .nav-link-text {
          position: relative;
          text-decoration: none;
          color: #000;
          font-size: 1rem;
          font-weight: 500;
          padding: 5px 0;
          transition: color 0.3s ease-in-out;
        }

        .nav-link-text::after {
          content: '';
          position: absolute;
          left: 0;
          bottom: -2px;
          width: 0;
          height: 2px;
          background-color: #c69e59;
          transition: width 0.3s ease-in-out;
        }

        .nav-link-text:hover::after {
          width: 100%;
        }

        .bell-icon {
          background-color: #c69e59;
          border-radius: 50%;
          width: 50px;
          height: 50px;
          color: white;
          font-size: 1.5rem;
          cursor: pointer;
          transition: background-color 0.3s ease;
          display: flex;
          align-items: center;
          justify-content: center;
        }

        .bell-icon:hover {
          background-color: #a48045;
        }

        .profile-image-container {
          width: 50px;
          height: 50px;
          border-radius: 50%;
          overflow: hidden;
          border: 3px solid #c69e59;
          margin-left: 15px;
          display: flex;
          align-items: center;
          justify-content: center;
        }

        .profile-image {
          width: 100%;
          height: 100%;
          object-fit: cover;
          display: ${isImageLoading ? "none" : "block"};
        }

        .profile-loader {
          width: 100%;
          height: 100%;
          background-color: #e0e0e0;
          animation: pulse 1.5s infinite;
        }

        @keyframes pulse {
          0% {
            background-color: #e0e0e0;
          }
          50% {
            background-color: #f0f0f0;
          }
          100% {
            background-color: #e0e0e0;
          }
        }

        .default-icon {
          font-size: 1.5rem;
          color: #c69e59;
        }

        #get-started-button {
          background-color: #c69e59;
          color: white;
          padding: 8px 16px;
          border: none;
          border-radius: 4px;
          cursor: pointer;
          font-size: 1rem;
          transition: background-color 0.3s ease;
        }

        #get-started-button:hover {
          background-color: #a48045;
        }
      `}</style>

      <Navbar expand="lg" className="navbar-custom">
        <Container className="navbar-collapse">
          <div className="brand-container">
            <Navbar.Brand href="#" style={{ padding: 0 }}>
              <span className="brand-text-tl">TL</span>
              <span className="brand-text-institute">Enterprise Institute</span>
            </Navbar.Brand>
          </div>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="nav-container">
              <Nav.Link href={userId ? "/dashboard" : "/faqs"} className="nav-link-text">
                {userId ? "Dashboard" : "FAQs"}
              </Nav.Link>
              <Nav.Link href="/contact" className="nav-link-text">Contact Us</Nav.Link>

              {/* Bell Icon */}
              <div className="bell-icon">
                <FaBell />
              </div>

              {/* Profile Image or Get Started Button */}
              {userId ? (
                <div className="profile-image-container">
                  <Link to="/dashboard/userProfile">
                    {isImageLoading ? (
                      <div className="profile-loader"></div>
                    ) : profileImageUrl ? (
                      <img
                        src={profileImageUrl}
                        alt="Profile"
                        className="profile-image"
                        onLoad={() => setIsImageLoading(false)}
                      />
                    ) : (
                      <div className="default-icon">
                        <BsPerson />
                      </div>
                    )}
                  </Link>
                </div>
              ) : (
                <button
                  id="get-started-button"
                  onClick={() => navigate("/login")}
                >
                  Get Started
                </button>
              )}

              {/* Log Out Button */}
              {userId && (
                <button
                  id="get-started-button"
                  onClick={handleSignOut}
                >
                  Log Out
                </button>
              )}
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </header>
  );
};

export default DashboardHeader;
