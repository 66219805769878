import React, { useState, useEffect } from "react";
import { Table, Button, Form, Pagination, Modal, Spinner } from "react-bootstrap";
import { FaEye, FaEdit, FaTrashAlt, FaPaperPlane } from "react-icons/fa";
import ViewPopUp from '../../components/pop_up/view';
import SendToPopUp from '../../components/pop_up/sendTo';
import { fetchIdea } from "../../controllers/admin_pannel/fetchIdeaSubmissionController";
import { doc, deleteDoc } from "firebase/firestore";
import { db } from "../../firebaseConfig";
import { useNavigate } from 'react-router-dom';

const AdminPannelIdeaManagement = () => {
  const [ideas, setIdeas] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const [showViewPopup, setShowViewPopup] = useState(false);
  const [showSendToPopup, setShowSendToPopup] = useState(false);

  const handleCloseViewPopup = () => setShowViewPopup(false);

  const [selectedIdeaId, setSelectedIdeaId] = useState(null); // Track selected idea ID

  const handleShowSendToPopup = (ideaId, ideaTitle) => {
    setSelectedIdeaId(ideaId, ideaTitle); // Set the selected idea ID
    setShowSendToPopup(true);
  };

  const handleCloseSendToPopup = () => setShowSendToPopup(false);

  const [showFilters, setShowFilters] = useState(false);
  const handleShowFilters = () => setShowFilters(true);
  const handleCloseFilters = () => setShowFilters(false);

  const handleDeleteIdea = async (id) => {
    // Confirm delete action
    if (window.confirm("Are you sure you want to delete this idea?")) {
      try {
        // Delete from Firestore
        const ideaRef = doc(db, "ideas", id); // Assuming 'ideas' is your collection name
        await deleteDoc(ideaRef);

        // Update state to remove the idea locally
        setIdeas((prevIdeas) => prevIdeas.filter((idea) => idea.id !== id));
        alert("Idea deleted successfully!");
      } catch (error) {
        console.error("Error deleting idea: ", error);
        alert("Failed to delete the idea. Please try again.");
      }
    }
  };

  const navigate = useNavigate(); // Initialize navigate

  const handleEditClick = (idea) => {
    navigate(`/admin/editIdeaForm`, { state: { idea } });
  };


  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await fetchIdea();
        setIdeas(data);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  return (
    <>
      <style type="text/css">
        {`
          /* No changes to your existing styles */
          .idea-management-container {
            max-width: 1200px;
            margin: 0 auto;
            padding: 20px;
            background: #fff;
          }
          @media (min-width: 992px) {
            .idea-management-container {
              padding-left: 242px;
            }
          }
          @media (max-width: 991px) {
            .idea-management-container {
              padding-left: 8px;
              padding-right: 8px;
            }
          }
          .page-title {
            font-size: 40px;
            font-weight: bold;
            margin-bottom: 30px;
            color: #000;
          }
          .header-actions {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            margin-bottom: 20px;
            gap: 4px;
          }
          .filter-button {
            background: transparent;
            border: 1px solid #d6a63e;
            color: #d6a63e;
            border-radius: 5px;
            padding: 6px 12px;
            font-weight: 500;
          }
          .filter-button:hover {
            background: #d6a63e;
            color: #fff;
          }
          .add-idea-button {
            background-color: #d6a63e;
            color: #fff;
            border: none;
            max-width: 200px;
            border-radius: 5px;
            padding: 8px 20px;
          }
          .add-idea-button:hover {
            background-color: #c59532;
          }
          .idea-table {
            margin-top: 20px;
          }
          .idea-table th {
            font-weight: 600;
            color: #000;
          }
          .idea-table td {
            vertical-align: middle;
          }
          .idea-image {
            width: 50px;
            height: 50px;
            border-radius: 8px;
            object-fit: cover;
          }
          .idea-title {
            font-weight: bold;
            margin-bottom: 5px;
            color: #000;
          }
          .idea-description {
            font-size: 14px;
            color: #555;
            display: -webkit-box;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
          }
          .icon-button {
            background: none;
            border: none;
            font-size: 18px;
            margin-right: 3px;
            cursor: pointer;
          }
          .icon-eye {
            color: #d6a63e;
          }
          .icon-edit,
          .icon-trash {
            color: #000;
          }
          .icon-send {
            color: #d6a63e;
          }
          .icon-button:hover {
            opacity: 0.8;
          }
          .pagination-container .pagination {
            color: #c59532;
          }
          .pagination-container .page-link {
            color: #c59532;
            border: 1px solid #c59532;
            background-color: transparent;
            border-radius: 5px;
          }
          .pagination-container .page-link:hover {
            background-color: #c59532 !important;
            color: #fff !important;
          }
          .pagination-container .active .page-link {
            background-color: #c59532;
            color: #fff;
            border-color: #c59532;
          }
        `}
      </style>

      <div className="idea-management-container">
        <h1 className="page-title">Idea Management</h1>
        <div className="header-actions">
          <Button className="filter-button" onClick={handleShowFilters}>
            Filters
          </Button>
          <Button href="/admin/ideaSubmission" className="add-idea-button">
            + Add Idea
          </Button>
        </div>

        {loading ? (
          <div className="loading-text">
            <Spinner animation="border" /> Loading...
          </div>
        ) : error ? (
          <div className="text-center text-danger my-4">
            <p>{error}</p>
          </div>
        ) : (
          <Table bordered hover className="idea-table">
            <thead>
              <tr>
                <th>
                  <Form.Check type="checkbox" />
                </th>
                <th>Title</th>
                <th>Description</th>
                <th>Status</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {ideas.map((idea) => (
                <tr key={idea.id}>
                  <td>
                    <Form.Check type="checkbox" />
                  </td>
                  <td>
                    <div className="d-flex align-items-center">
                      <img
                        src={idea.ideaImage || "image"}
                        alt="Idea Thumbnail"
                        className="idea-image"
                      />
                      <div className="ms-3">
                        <p className="idea-title">{idea.title}</p>
                      </div>
                    </div>
                  </td>
                  <td>
                    <p className="idea-description">{idea.description}</p>
                  </td>
                  <td>
                    <FaEye
                      className="icon-button icon-eye"
                      onClick={() => {
                        setSelectedIdeaId(idea.id); // Set the ideaId to state
                        setShowViewPopup(true);    // Show the popup
                      }}
                    />

                    {/* Pass the selected idea ID to the ViewPopUp */}
                    <ViewPopUp
                      show={showViewPopup}
                      handleClose={handleCloseViewPopup}
                      ideaId={selectedIdeaId} // Pass the selected ideaId as a prop
                    />
                  </td>
                  <td>
                    <div className="d-flex">
                      <button onClick={() => handleEditClick(idea)} className="icon-button">
                        <FaEdit className="icon-edit" />
                      </button>
                      <button
                        className="icon-button"
                        onClick={() => handleDeleteIdea(idea.id)}
                      >
                        <FaTrashAlt className="icon-trash" />
                      </button>
                      {idea.status !== "Won Idea" && (
                        <button
                          className="icon-button"
                          onClick={() => handleShowSendToPopup(idea.id, idea.title)} // Pass idea ID
                        >
                          <FaPaperPlane className="icon-send" />
                        </button>
                      )}

                      {/* Pass the selected idea ID to SendToPopUp */}
                      <SendToPopUp
                        show={showSendToPopup}
                        handleClose={handleCloseSendToPopup}
                        ideaId={selectedIdeaId} // Pass ideaId as prop
                      />
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>

          </Table>
        )}

        <div className="pagination-container">
          <Pagination>
            <Pagination.Prev className="pe-2">Previous</Pagination.Prev>
            <Pagination.Item active>Page 1 of 10</Pagination.Item>
            <Pagination.Next className="ps-2">Next</Pagination.Next>
          </Pagination>
        </div>
      </div>

      {/* Filters Modal */}
      <Modal show={showFilters} onHide={handleCloseFilters} centered>
        <Modal.Body>
          <div className="filters-popup">
            <h4 className="text-center mb-4">Filters</h4>
            <Form>
              <Form.Group className="mb-3">
                <Form.Label>Category</Form.Label>
                <Form.Select>
                  <option>Technology</option>
                  <option>Health</option>
                  <option>Education</option>
                </Form.Select>
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Niche</Form.Label>
                <Form.Select>
                  <option>AI</option>
                  <option>Blockchain</option>
                  <option>IoT</option>
                </Form.Select>
              </Form.Group>
              <Button className="add-idea-button w-100">Apply Filter</Button>
            </Form>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default AdminPannelIdeaManagement;
