import React, { useEffect, useState } from 'react';
import { Form, Button, Row, Col, Container } from 'react-bootstrap';
import { FaEdit } from 'react-icons/fa';
import UserService from '../../controllers/currentUserId'; 
import { db, storage } from '../../firebaseConfig'; // Adjust the import path as needed
import { doc, updateDoc, getDoc } from 'firebase/firestore'; // Firestore imports
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage'; // Firebase Storage imports

function UserProfilePage() {
  const [userData, setUserData] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    address: '',
    country: '',
    nationality: '',
    investmentRange: '999 - 3000 $',
    category: 'Technology',
    niche: 'AI',
  });

  const [imageSrc, setImageSrc] = useState(null);

  const handleImageUpload = async (event) => {
    const file = event.target.files[0];
    if (file) {
      try {
        const userId = UserService.getCurrentUserId();
        const imageRef = ref(storage, `profileImages/${userId}`);
        await uploadBytes(imageRef, file);

        const downloadURL = await getDownloadURL(imageRef);
        setImageSrc(downloadURL);

        const userRef = doc(db, 'users', userId);
        await updateDoc(userRef, { profileImage: downloadURL });

        console.log('Profile image updated in Firestore');
      } catch (error) {
        console.error('Error uploading image:', error);
      }
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      const userId = UserService.getCurrentUserId();
      try {
        const userRef = doc(db, 'users', userId);
        const docSnap = await getDoc(userRef);

        if (docSnap.exists()) {
          const data = docSnap.data();
          setUserData(data);
          setFormData({
            firstName: data.firstName,
            lastName: data.lastName,
            email: data.email,
            password: '', // Don't fetch password for security reasons
            address: data.address,
            country: data.country,
            nationality: data.nationality,
            investmentRange: data.investmentRange,
            category: data.category,
            niche: data.niche,
          });
          if (data.profileImage) {
            setImageSrc(data.profileImage);
          }
        }
      } catch (error) {
        console.error('Error fetching user profile:', error);
      }
    };
    fetchData();
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSave = async () => {
    try {
      const userId = UserService.getCurrentUserId();
      const userRef = doc(db, 'users', userId);
      await updateDoc(userRef, {
        firstName: formData.firstName,
        lastName: formData.lastName,
        email: formData.email,
        address: formData.address,
        country: formData.country,
        nationality: formData.nationality,
        investmentRange: formData.investmentRange,
        category: formData.category,
        niche: formData.niche,
      });

      console.log('User data updated in Firestore');
      setIsEditing(false); // Disable editing after saving
    } catch (error) {
      console.error('Error updating user profile in Firestore:', error);
    }
  };

  const handleEditClick = () => {
    setIsEditing(true); // Enable editing
  };

  if (!userData) {
    return <div>Loading...</div>;
  }

  return (
    <Container className="d-flex justify-content-center align-items-center min-vh-100">
      <div className="create-account-container">
        <div className="d-flex align-items-center mb-4">
          <h2 className="profile-header">Profile</h2>
          <FaEdit className="edit-icon ms-3" onClick={handleEditClick} />
        </div>
        <Container
          className="d-flex flex-column align-items-center justify-content-center"
          style={{
            width: '70px',
            height: '70px',
            border: '1px solid black',
            position: 'relative',
            textAlign: 'center',
            overflow: 'hidden',
            borderRadius: '50%', // Optional for circular container
            backgroundColor: '#f0f0f0', // Light gray background
          }}
        >
          {imageSrc ? (
            <img
              src={imageSrc}
              alt="Profile"
              style={{ width: '100%', height: '100%', objectFit: 'cover' }}
            />
          ) : (
            <i
              className="bi bi-person-circle"
              style={{ fontSize: '40px', color: '#6c757d' }} // Bootstrap icon for a user
            ></i>
          )}

          <input
            type="file"
            accept="image/*"
            style={{ display: 'none' }}
            id="upload-input"
            onChange={handleImageUpload}
            disabled={!isEditing}
          />
          {isEditing && (
            <Button
              variant="success"
              size="sm"
              style={{
                position: 'absolute',
                bottom: 16,
                left: 0,
                width: '100%',
                height: '30px',
              }}
              onClick={() => document.getElementById('upload-input').click()}
            >
              Add
            </Button>
          )}
        </Container>
        <Form className="account-form">
          <Row>
            <Col md={6}>
              <Form.Group controlId="firstName">
                <Form.Label className="form-label">First Name</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter your first name"
                  name="firstName"
                  value={formData.firstName}
                  onChange={handleInputChange}
                  disabled={!isEditing}
                />
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group controlId="lastName">
                <Form.Label className="form-label">Last Name</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter your last name"
                  name="lastName"
                  value={formData.lastName}
                  onChange={handleInputChange}
                  disabled={!isEditing}
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <Form.Group controlId="email">
                <Form.Label className="form-label">Email</Form.Label>
                <Form.Control
                  type="email"
                  placeholder="Enter your email"
                  name="email"
                  value={formData.email}
                  onChange={handleInputChange}
                  disabled={!isEditing}
                />
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group controlId="password">
                <Form.Label className="form-label">Password</Form.Label>
                <Form.Control
                  type="password"
                  placeholder="Enter your password"
                  name="password"
                  value={formData.password}
                  onChange={handleInputChange}
                  disabled={!isEditing}
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <Form.Group controlId="address">
                <Form.Label className="form-label">Address</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter your address"
                  name="address"
                  value={formData.address}
                  onChange={handleInputChange}
                  disabled={!isEditing}
                />
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group controlId="country">
                <Form.Label className="form-label">Country</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter your country"
                  name="country"
                  value={formData.country}
                  onChange={handleInputChange}
                  disabled={!isEditing}
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <Form.Group controlId="nationality">
                <Form.Label className="form-label">Nationality</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter your nationality"
                  name="nationality"
                  value={formData.nationality}
                  onChange={handleInputChange}
                  disabled={!isEditing}
                />
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group controlId="investmentRange">
                <Form.Label className="form-label">Investment Range</Form.Label>
                <Form.Control
                  as="select"
                  name="investmentRange"
                  value={formData.investmentRange}
                  onChange={handleInputChange}
                  disabled={!isEditing}
                >
                  <option>999 - 3000 $</option>
                  <option>3001 - 5000 $</option>
                  <option>5001 - 10000 $</option>
                </Form.Control>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <Form.Group controlId="category">
                <Form.Label className="form-label">Category</Form.Label>
                <Form.Control
                  as="select"
                  name="category"
                  value={formData.category}
                  onChange={handleInputChange}
                  disabled={!isEditing}
                >
                  <option>Technology</option>
                  <option>Finance</option>
                  <option>Health</option>
                </Form.Control>
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group controlId="niche">
                <Form.Label className="form-label">Niche</Form.Label>
                <Form.Control
                  as="select"
                  name="niche"
                  value={formData.niche}
                  onChange={handleInputChange}
                  disabled={!isEditing}
                >
                  <option>AI</option>
                  <option>Blockchain</option>
                  <option>Data Science</option>
                </Form.Control>
              </Form.Group>
            </Col>
          </Row>
          {isEditing && (
            <Button onClick={handleSave} variant="primary" type="button" className="submit-btn">
              Save
            </Button>
          )}
        </Form>

        <style jsx>{`
          .signup-link {
            color: #c69e59;
            text-decoration: none;
            font-weight: bold;
          }

          .signup-link:hover {
            text-decoration: underline;
          }
          .create-account-container {
            width: 100%;
            max-width: 800px;
            padding: 2.5rem;
            background-color: #ffffff;
            border-radius: 15px;
            box-shadow: 0 6px 18px rgba(0, 0, 0, 0.1);
            margin-top: 20px;
            margin-bottom: 20px;
          }
          .account-form {
            margin-top: 1rem;
          }
          .form-label {
            display: block;
            font-weight: 600;
            margin-bottom: 0.3rem;
            color: #333;
            text-align: left;
          }
          .submit-btn {
            width: 30%;
            background-color: #c69e59;
            border-radius: 30px;
            border: none;
            padding: 0.75rem;
            font-weight: bold;
            font-size: 1rem;
            margin-top: 1rem;
            margin-left: auto;
            margin-right: auto;
            display: block;
          }
          .edit-icon {
            font-size: 1.2rem;
            color: #c69e59;
            cursor: pointer;
          }

          .edit-icon:hover {
            color: #b07e46;
          }
          .submit-btn:hover {
            background-color: #b07e46;
          }
        `}</style>
      </div>
    </Container>
  );
}

export default UserProfilePage;
