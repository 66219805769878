import { doc, setDoc, getDoc, updateDoc, arrayUnion } from "firebase/firestore";
import { db } from "../../../firebaseConfig";

export const updateUserIdeas = async (userId, ideaId) => {
  try {
    // Reference to the user_idea_statuses document
    const userIdeaDocRef = doc(db, "user_idea_statuses", `${userId}_${ideaId}`);

    // Check if the document already exists
    const docSnapshot = await getDoc(userIdeaDocRef);

    if (docSnapshot.exists()) {
      console.log(`Document for user ${userId} and idea ${ideaId} already exists.`);
      return; // Exit without creating/updating
    }

    // If document doesn't exist, create it in user_idea_statuses
    await setDoc(userIdeaDocRef, {
      ideaId: ideaId,
      status: "Pending",
      userId: userId,
      timestamp: new Date().toISOString(), // Adding a timestamp
    });
    console.log(`Idea ${ideaId} set for user ${userId} with status "pending"`);

    // Update the users collection
    const userDocRef = doc(db, "users", userId);
    await updateDoc(userDocRef, {
      ideas: arrayUnion(ideaId), // Add ideaId to the user's ideas array
      status: "To be discussed", // Update the user's status
    });
    console.log(`User ${userId} updated with idea ${ideaId} and status "To be discussed"`);

  } catch (error) {
    console.error(`Failed to update user ${userId} with idea ${ideaId}`, error);
    throw error;
  }
};
