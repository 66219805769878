import React, { useState } from "react";
import { Container, Row, Col, Card, Button } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { useLocation, useNavigate } from "react-router-dom";
import { updateDoc, getFirestore, query, where, collection, getDocs } from "firebase/firestore";
import UserService from "../../controllers/currentUserId";
import PitchCollaborationPopUp from "../../components/pop_up/pitchCollabrationPopUp";
import DeclinePopUp from "../../components/pop_up/declinePopup";

const IdeaProposalPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { idea } = location.state || {};
  const [showPitchPopup, setShowPitchPopup] = useState(false);
  const [showDeclinePopup, setShowDeclinePopup] = useState(false);

  const isIdeaWon = idea.status === "Won Idea";

  const handleClosePitchPopup = () => setShowPitchPopup(false);

  const handlePitchSubmit = async (pitch) => {
    try {
      const db = getFirestore();
      const currentUserId = UserService.getCurrentUserId();
      const userIdeaStatusesRef = collection(db, "user_idea_statuses");

      const q = query(
        userIdeaStatusesRef,
        where("userId", "==", currentUserId),
        where("ideaId", "==", idea.id)
      );

      const querySnapshot = await getDocs(q);
      if (!querySnapshot.empty) {
        const docRef = querySnapshot.docs[0].ref;
        await updateDoc(docRef, {
          status: "Collaboration",
          pitch: pitch,
        });
        console.log("Pitch and status updated to Collaboration");
      } else {
        console.error("No matching document found in user_idea_statuses.");
      }
    } catch (error) {
      console.error("Error updating pitch and status:", error);
    }
    handleClosePitchPopup();
  };

  const handleDeclineClick = async () => {
    try {
      const db = getFirestore();
      const currentUserId = UserService.getCurrentUserId();
      const userIdeaStatusesRef = collection(db, "user_idea_statuses");

      const q = query(
        userIdeaStatusesRef,
        where("userId", "==", currentUserId),
        where("ideaId", "==", idea.id)
      );

      const querySnapshot = await getDocs(q);
      if (!querySnapshot.empty) {
        const docRef = querySnapshot.docs[0].ref;
        await updateDoc(docRef, { status: "Decline" });
        console.log("Status updated to Decline");
      } else {
        console.error("No matching document found in user_idea_statuses.");
      }
      setShowDeclinePopup(true);
    } catch (error) {
      console.error("Error updating status to Decline:", error);
    }
  };

  return (
    <Container className="py-5">
      <style>
        {`
          .idea-card {
            padding: 40px;
            border-radius: 12px;
            box-shadow: 0 10px 30px rgba(0, 0, 0, 0.15);
            background-color: #ffffff;
          }
          .idea-title {
            font-size: 2.75rem;
            font-weight: 700;
            color: #2c3e50;
            margin-bottom: 20px;
            text-align: start;
          }
          .idea-purpose {
            font-size: 1.125rem;
            color: #555;
            line-height: 1.8;
            text-align: start;
            margin-bottom: 30px;
          }
          .section-title {
            font-size: 1.75rem;
            font-weight: 600;
            color: #34495e;
            margin-bottom: 20px;
            text-align: start;
          }
          .section-content {
            font-size: 1rem;
            color: #555;
            line-height: 1.8;
            text-align: start;
          }
          .highlight-text {
            color: #c69e59;
            font-weight: 600;
          }
          .action-buttons {
            gap: 20px;
            flex-wrap: wrap;
          }
          .action-buttons .btn {
            min-width: 160px;
            font-weight: 600;
            border-radius: 30px;
            box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
            padding: 10px 20px;
          }
          .btn-success {
            background-color: #27ae60;
            border: none;
          }
          .btn-danger {
            background-color: #e74c3c;
            border: none;
          }
          .btn-warning {
            background-color: #f39c12;
            border: none;
            color: #ffffff;
          }
          .btn:hover {
            opacity: 0.9;
          }
          @media (max-width: 768px) {
            .idea-title {
              font-size: 2.2rem;
            }
            .section-title {
              font-size: 1.5rem;
            }
            .action-buttons {
              flex-direction: column;
              align-items: center;
              gap: 10px;
            }
            .action-buttons .btn {
              width: 100%;
            }
          }
        `}
      </style>

      <Card className="idea-card">
        <Row className="idea-section align-items-center">
          <Col xs={12} md={6}>
            <h2 className="idea-title">{idea.title}</h2>
            <p className="idea-purpose">{idea.description}</p>
          </Col>
          <Col xs={12} md={6} className="text-center">
            <img
              src="/images/slider.png"
              alt="Idea"
              className="idea-image"
              height="300px"
              width="500px"
            />
          </Col>
        </Row>

        <Row className="idea-section">
          <Col>
            <h4 className="section-title"><strong>Category </strong>{idea.category}</h4>
            <p className="section-content">
              <strong>Concept:</strong> {idea.longDescription}
            </p>
            <p className="section-content">
              <strong>Profitability Estimate:</strong>
            </p>
            <ul className="section-content">
              <li>
                <strong>Initial Revenue Projection (Year 1):</strong>{" "}
                <span className="highlight-text">$100,000 to $150,000</span>
              </li>
              <li>
                Annual growth of <span className="highlight-text">25%</span>{" "}
                driven by increasing demand for eco-friendly products.
              </li>
              <li>
                Break-even point expected within{" "}
                <span className="highlight-text">12-18 months</span>.
              </li>
            </ul>
          </Col>
        </Row>

        <Row className="idea-section">
          <Col>
            <h4 className="section-title">Initial Investment & Key Resources</h4>
            <ul className="section-content">
              <li>
                <strong>Initial Investment:</strong>{" "}
                <span className="highlight-text">$150,000 - $200,000</span>
              </li>
              <li>
                <strong>Key Costs:</strong> Production facility, specialized
                machinery, marketing, and staffing.
              </li>
              <li>
                <strong>Key Team Members:</strong> Eco-friendly material
                scientist, production engineer, sales/marketing expert.
              </li>
            </ul>
          </Col>
        </Row>

        <Row className="idea-section">
          <Col>
            <h4 className="section-title">Recommended Contacts</h4>
            <ul className="section-content">
              <li>
                <strong>Web Designer:</strong> Specializes in eco-business
                websites.
              </li>
              <li>
                <strong>Software Engineer:</strong> Expertise in logistics tools.
              </li>
              <li>
                <strong>Packaging Suppliers:</strong> Sustainable packaging
                materials.
              </li>
            </ul>
          </Col>
        </Row>

        <Row className="text-center">
          <Col className="d-flex justify-content-center action-buttons">
            <Button
              onClick={() => navigate(`/dashboard/paymentConfirmation`, { state: { idea } })}
              variant="success"
              size="lg"
              disabled={isIdeaWon}
            >
              Accept
            </Button>
            <Button
              onClick={handleDeclineClick}
              variant="danger"
              size="lg"
              disabled={isIdeaWon}
            >
              Decline
            </Button>
            <Button
              variant="warning"
              size="lg"
              onClick={() => setShowPitchPopup(true)}
              disabled={isIdeaWon}
            >
              Collaborate
            </Button>
            <PitchCollaborationPopUp
              show={showPitchPopup}
              handleClose={handleClosePitchPopup}
              onSubmit={handlePitchSubmit}
            />
            <DeclinePopUp
              show={showDeclinePopup}
              handleClose={() => setShowDeclinePopup(false)}
            />
          </Col>
        </Row>
      </Card>
    </Container>
  );
};

export default IdeaProposalPage;
