import React, { useEffect, useState, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { Container, Table, Image, Button, Row, Col, Form } from "react-bootstrap";
import { BsCheckCircle, BsXCircle, BsEye, BsTrash } from "react-icons/bs";
import { collection, query, where, getDocs } from "firebase/firestore"; 
import { auth,db } from "../../firebaseConfig";

const UserDashboard = () => {
  const navigate = useNavigate();
  const [ideaList, setIdeaList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [userId, setUserId] = useState(null);

  const handleRowClick = (idea) => {
    navigate(`/dashboard/ideaPropsal`, { state: { idea } });
  };

const fetchUserIdeas = useCallback(async () => {
  setIsLoading(true);
  try {
    if (!userId) {
      console.error("No user ID found!");
      return;
    }

    // Fetch user_idea_statuses data where userId matches
    const userIdeaStatusesQuery = query(
      collection(db, "user_idea_statuses"),
      where("userId", "==", userId)
    );
    const userIdeaStatusesSnapshot = await getDocs(userIdeaStatusesQuery);

    if (userIdeaStatusesSnapshot.empty) {
      console.log("No ideas found for the current user.");
      setIdeaList([]);
      return;
    }

    // Extract ideaIds from user_idea_statuses
    const ideaIds = userIdeaStatusesSnapshot.docs.map((doc) => doc.data().ideaId);

    // Fetch all ideas and filter them based on the extracted ideaIds
    const ideasCollectionSnapshot = await getDocs(collection(db, "ideas"));
    const allIdeas = ideasCollectionSnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));

    const userIdeas = allIdeas.filter((idea) => ideaIds.includes(idea.id));

    // Update the state with the fetched ideas
    setIdeaList(userIdeas);
  } catch (error) {
    console.error("Error fetching user ideas:", error);
  } finally {
    setIsLoading(false);
  }
}, [userId]);
// Include userId as a dependency

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        setUserId(user.uid);
      } else {
        setUserId(null);
      }
    });

    return () => {
      unsubscribe();
    };
  }, []);

  useEffect(() => {
    if (userId) {
      fetchUserIdeas();
    }
  }, [userId, fetchUserIdeas]); // Add fetchUserIdeas as a dependency

  const truncateDescription = (text) => {
    const maxLength = 100;
    return text.length > maxLength ? `${text.substring(0, maxLength)}...` : text;
  };

  const styles = {
    container: {
      padding: "40px 20px",
      backgroundColor: "#f7f9fc",
      maxWidth: "1200px",
      margin: "auto",
    },
    header: {
      textAlign: "center",
      marginBottom: "30px",
    },
    title: {
      fontSize: "2.5rem",
      fontWeight: "700",
      color: "#333",
      marginBottom: "10px",
    },
    subtitle: {
      fontSize: "1.1rem",
      color: "#777",
      marginBottom: "30px",
    },
    table: {
      backgroundColor: "#ffffff",
      borderRadius: "12px",
      boxShadow: "0 10px 20px rgba(0, 0, 0, 0.1)",
      padding: "15px",
    },
    tableHeader: {
      fontWeight: "700",
      color: "#555",
      fontSize: "1rem",
      padding: "15px",
      backgroundColor: "#f0e6d6",
      borderTopLeftRadius: "12px",
      borderTopRightRadius: "12px",
    },
    row: {
      padding: "15px",
      borderBottom: "1px solid #f2f2f2",
      transition: "background-color 0.2s ease",
      cursor: "pointer",
    },
    rowHover: {
      backgroundColor: "#f9f5ec",
    },
    titleText: {
      fontWeight: "600",
      color: "#2c3e50",
      maxWidth: "200px",
      fontSize: "1.1rem",
    },
    description: {
      maxWidth: "300px",
      fontSize: "0.9rem",
      textAlign: "center",
      color: "#555",
    },
    investment: {
      color: "#27ae60",
      fontWeight: "600",
    },
    actions: {
      display: "flex",
      gap: "10px",
    },
    actionIcon: {
      cursor: "pointer",
      fontSize: "1.3rem",
      color: "#666",
      transition: "transform 0.2s ease",
    },
    actionIconHover: {
      transform: "scale(1.1)",
    },
    actionGreen: {
      color: "#28a745",
    },
    actionRed: {
      color: "#e74c3c",
    },
    btnStatus: {
      fontWeight: "600",
      border: "none",
      padding: "5px 10px",
    },
    btnAccepted: {
      backgroundColor: "#28a745",
      color: "white",
    },
    btnPending: {
      backgroundColor: "orange",
      color: "white",
    },
    btnRejected: {
      backgroundColor: "#e74c3c",
      color: "white",
    },
    seeMoreBtn: {
      backgroundColor: "#c69e59",
      border: "none",
      color: "white",
      fontWeight: "600",
      padding: "10px 30px",
      borderRadius: "30px",
      boxShadow: "0 4px 10px rgba(198, 158, 89, 0.3)",
      transition: "background-color 0.3s ease",
    },
  };

  return (
    <Container fluid style={styles.container}>
      <div style={styles.header}>
        <h2 style={styles.title}>Idea Dashboard</h2>
        <p style={styles.subtitle}>
          Explore the innovative ideas shared on the platform
        </p>
      </div>

      <Table responsive="sm" style={styles.table}>
        <thead>
          <tr>
            <th style={styles.tableHeader} className="text-center">Select</th>
            <th style={styles.tableHeader} className="text-start">Title</th>
            <th style={styles.tableHeader} className="text-start">Description</th>
            <th style={styles.tableHeader} className="text-center">Investment</th>
            <th style={styles.tableHeader} className="text-center">Status</th>
            <th style={styles.tableHeader} className="text-center">Action Buttons</th>
          </tr>
        </thead>
        <tbody>
          {isLoading ? (
            <tr>
              <td colSpan="6" className="text-center">Loading...</td>
            </tr>
          ) : (
            ideaList.map((idea) => (
              <tr
              key={idea.id}
              style={styles.row}
              onClick={() => handleRowClick(idea)}
              >
                <td className="text-center">
                  <Form.Check type="checkbox" />
                </td>
                <td className="text-start">
                  <div className="d-flex align-items-center">
                    <Image
                      src={idea.ideaImage || "defaultImagePath.jpg"}
                      alt="Idea"
                      roundedCircle
                      width={50}
                      height={50}
                      className="me-3"
                    />
                    <span style={styles.titleText}>{idea.title}</span>
                  </div>
                </td>
                <td style={styles.description}>
                  {truncateDescription(idea.description)}
                </td>
                <td style={styles.investment} className="text-center">
                  {idea.longDescription}
                </td>
                <td className="text-center">
                  <Button
                    style={{
                      ...styles.btnStatus,
                      ...(idea.status === "Pending"
                        ? styles.btnPending
                        : idea.status === "Rejected"
                        ? styles.btnRejected
                        : styles.btnAccepted),
                    }}
                  >
                    {idea.status}
                  </Button>
                </td>
                <td className="text-center">
                  <div style={styles.actions}>
                    <BsEye style={styles.actionIcon} />
                    {idea.status === "Pending" && (
                      <>
                        <BsCheckCircle style={{ ...styles.actionIcon, ...styles.actionGreen }} />
                        <BsXCircle style={{ ...styles.actionIcon, ...styles.actionRed }} />
                      </>
                    )}
                    {idea.status === "Rejected" && (
                      <BsTrash style={{ ...styles.actionIcon, ...styles.actionRed }} />
                    )}
                  </div>
                </td>
              </tr>
            ))
          )}
        </tbody>
      </Table>

      <Row className="justify-content-center mt-4">
        <Col xs="auto">
          <Button style={styles.seeMoreBtn}>Send More Ideas</Button>
        </Col>
      </Row>
    </Container>
  );
};

export default UserDashboard;
