import React from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';

const Onboarding = () => {
  // Inline CSS Styles as JavaScript objects
  const styles = {
    title: {
      fontSize: '2rem',
      fontWeight: 'bold',
      marginBottom: '0.5rem',
    },
    subtitle: {
      color: '#666',
      fontSize: '1rem',
      marginBottom: '2rem',
    },
    card: {
      border: 'none',
      borderRadius: '12px',
      padding: '20px',
      backgroundColor: '#f7f8fa',
      transition: 'box-shadow 0.3s ease',
    },
    cardHover: {
      boxShadow: '0px 8px 16px rgba(0, 0, 0, 0.1)',
    },
    icon: {
      backgroundColor: '#f1c40f',
      borderRadius: '50%',
      width: '60px',
      height: '60px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      margin: '0 auto 15px auto',
    },
    cardTitle: {
      fontSize: '1.25rem',
      fontWeight: 'bold',
      marginTop: '10px',
      marginBottom: '0.5rem',
    },
    cardText: {
      color: '#666',
      fontSize: '0.95rem',
      lineHeight: '1.5',
    },
  };

  return (
    <Container className="text-center my-5">
      <h2 style={styles.title}>How it Work</h2>
      <p style={styles.subtitle}>
        Discover how easy it is to turn ideas into opportunities at TL Enterprise.
      </p>

      <Row className="justify-content-center">
        <Col xs={12} md={4} className="my-3">
          <Card style={{ ...styles.card, ':hover': styles.cardHover }}>
            <Card.Body>
              <div style={styles.icon}> {/* Placeholder for icon */}
                <img src="/images/onboarding.png" alt="Icon 1" width="40" />
              </div>
              <Card.Title style={styles.cardTitle}>Get Started</Card.Title>
              <Card.Text style={styles.cardText}>
                Buy a ticket to receive a ready-made, profitable business idea tailored to your interests.
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>

        <Col xs={12} md={4} className="my-3">
          <Card style={{ ...styles.card, ':hover': styles.cardHover }}>
            <Card.Body>
              <div style={styles.icon}>
                <img src="/images/onboarding.png" alt="Icon 2" width="40" />
              </div>
              <Card.Title style={styles.cardTitle}>Review & Decide</Card.Title>
              <Card.Text style={styles.cardText}>
                Accept, decline, or collaborate on the idea. If accepted, purchase the full concept for <strong>999 EUR</strong>.
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>

        <Col xs={12} md={4} className="my-3">
          <Card style={{ ...styles.card, ':hover': styles.cardHover }}>
            <Card.Body>
              <div style={styles.icon}>
                <img src="/images/onboarding.png" alt="Icon 3" width="40" />
              </div>
              <Card.Title style={styles.cardTitle}>Launch with Support</Card.Title>
              <Card.Text style={styles.cardText}>
                Get a complete roadmap, contacts, our guidance as co-creators with small equity stake.
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default Onboarding;
