import React, { useState } from 'react';
import { Navbar, Nav, Container, Modal, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { BsCheckCircle, BsBoxArrowRight } from 'react-icons/bs';
import { FaBell } from 'react-icons/fa';

const AdminPannelHeader = () => {
    const [showNotificationModal, setShowNotificationModal] = useState(false);
    const [showLogoutModal, setShowLogoutModal] = useState(false);

    const handleShowNotification = () => setShowNotificationModal(true);
    const handleCloseNotification = () => setShowNotificationModal(false);

    const handleShowLogout = () => setShowLogoutModal(true);
    const handleCloseLogout = () => setShowLogoutModal(false);

    const notifications = [
        {
            id: 1,
            icon: <BsCheckCircle className="text-success me-2" />,
            title: "Order Confirmed",
            message: "Your checkout is successful, product is on the way.",
            timestamp: "Just now",
        },
    ];

    const NotificationItem = ({ notification }) => (
        <div key={notification.id} className="notification-item d-flex align-items-start mb-3">
            <div className="notification-icon">{notification.icon}</div>
            <div className="notification-text flex-grow-1">
                <div className="fw-bold">{notification.title}</div>
                <div className="text-muted">{notification.message}</div>
                <small className="text-muted">{notification.timestamp}</small>
            </div>
        </div>
    );

    const styles = {
        bellIcon: {
            backgroundColor: '#c69e59', // Color for the bell icon background
            borderRadius: '50%',
            width: '50px',
            height: '50px',
            color: 'white',
            fontSize: '1.5rem',
            cursor: 'pointer',
            transition: 'background-color 0.3s ease',
        },
        bellIconHover: {
            backgroundColor: '#a48045',
        },
        navbar: {
            backgroundColor: 'white',
            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
            padding: '4px 0px',
            borderBottom: '1px solid #e6e6e6',
            zIndex: 1000,
        },
        brand: {
            fontWeight: 'bold',
            color: 'black',
            fontSize: '1.2rem',
        },
        brandSpan: {
            color: '#c69e59',
            fontSize: '1.5rem',
        },
        iconContainer: {
            display: 'flex',
            alignItems: 'center',
        },
        notificationIcon: {
            color: '#c69e59',
            margin: '0 10px',
            position: 'relative',
            cursor: 'pointer',
        },
        logoutIcon: {
            color: 'red',
            margin: '0 10px',
            position: 'relative',
            cursor: 'pointer',
        },
        button: {
            backgroundColor: '#c69e59',
            color: '#ffffff',
        },
        buttonHover: {
            backgroundColor: '#c69e59',
            boxShadow: '0px 5px 15px rgba(0, 91, 187, 0.3)',
        },
        profileImageContainer: {
            width: '50px',
            height: '50px',
            borderRadius: '50%',
            overflow: 'hidden',
            border: '3px solid #c69e59', // Border color matching the bell icon
            marginLeft: '15px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        },
        profileImage: {
            width: '100%',
            height: '100%',
            objectFit: 'cover',
        },
    };


    return (
        <>
            <Navbar expand="lg" className="sticky-top" style={styles.navbar}>
                <Container fluid className="d-flex justify-content-between align-items-center pt-4">
                    <Navbar.Brand as={Link} to="/dashboard" style={styles.brand}>
                        TL <span style={styles.brandSpan}>Interprise</span>
                    </Navbar.Brand>

                    {/* Removed search bar here */}

                    <Nav className="d-flex align-items-center pe-4 me-4" style={styles.iconContainer}>
                        <Nav.Link
                            onClick={handleShowNotification}
                            style={styles.notificationIcon}
                        >
                            <div style={styles.bellIcon}>
                                <FaBell />
                            </div>
                        </Nav.Link>
                        <div style={styles.profileImageContainer}>
                            <Link to="/dashboard/userProfile">
                                <img
                                    src="/images/onboarding.png"
                                    alt="Profile"
                                    style={styles.profileImage}
                                    onClick={() => console.log('Navigating to profile...')}
                                />
                            </Link>

                        </div>
                        <Nav.Link
                            onClick={handleShowLogout}
                            style={styles.logoutIcon}
                        >
                            <BsBoxArrowRight size={20} />
                        </Nav.Link>
                    </Nav>
                </Container>
            </Navbar>

            {/* Notification Modal */}
            <Modal show={showNotificationModal} onHide={handleCloseNotification} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Notifications</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {notifications.length > 0 ? (
                        notifications.map((notification) => (
                            <NotificationItem key={notification.id} notification={notification} />
                        ))
                    ) : (
                        <p className="text-center text-muted">No new notifications</p>
                    )}
                </Modal.Body>
            </Modal>

            {/* Logout Confirmation Modal */}
            <Modal show={showLogoutModal} onHide={handleCloseLogout} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Confirm Logout</Modal.Title>
                </Modal.Header>
                <Modal.Body>Are you sure you want to log out?</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseLogout}>
                        No
                    </Button>
                    <Button style={styles.button} href='/login'>
                        Yes
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default AdminPannelHeader;
