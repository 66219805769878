import React, { useState } from 'react';
import { Form, Button, Row, Col, Container, Alert } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom'; 
import { registerUser } from '../../controllers/registerController'; 
import LinkedTermsPopUp from '../../components/pop_up/linkedTerms';

function Register() {

  const [showPopup, setShowPopup] = useState(false);

  const handleShow = (e) => {
    e.preventDefault(); // Prevent navigation when the popup is shown
    setShowPopup(true);
  };

  const handleClose = () => setShowPopup(false);

  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    address: '',
    role:'user',
    status:'Awaiting for Idea',
    country: '',
    nationality: '',
    investmentRange: '',
    category: '',
    niche: '',
  });

  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [formErrors, setFormErrors] = useState({});
  
  const navigate = useNavigate(); // Initialize useNavigate hook

  const handleInputChange = (e) => {
    const { id, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [id]: value }));
  };

  const validateForm = () => {
    let errors = {};
    let isValid = true;

    // Check if required fields are filled
    if (!formData.firstName) {
      isValid = false;
      errors.firstName = 'First Name is required.';
    }
    if (!formData.lastName) {
      isValid = false;
      errors.lastName = 'Last Name is required.';
    }
    if (!formData.email) {
      isValid = false;
      errors.email = 'Email is required.';
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      isValid = false;
      errors.email = 'Email is not valid.';
    }
    if (!formData.password) {
      isValid = false;
      errors.password = 'Password is required.';
    } else if (formData.password.length < 6) {
      isValid = false;
      errors.password = 'Password must be at least 6 characters long.';
    }
    if (!formData.address) {
      isValid = false;
      errors.address = 'Address is required.';
    }
    if (!formData.country) {
      isValid = false;
      errors.country = 'Country is required.';
    }
    if (!formData.nationality) {
      isValid = false;
      errors.nationality = 'Nationality is required.';
    }
    if (!formData.investmentRange) {
      isValid = false;
      errors.investmentRange = 'Investment Range is required.';
    }
    if (!formData.category) {
      isValid = false;
      errors.category = 'Category is required.';
    }
    if (!formData.niche) {
      isValid = false;
      errors.niche = 'Niche is required.';
    }

    setFormErrors(errors);
    return isValid;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setErrorMessage('');
    setSuccessMessage('');

    if (!validateForm()) {
      return; // Don't proceed if form is invalid
    }

    try {
      setLoading(true);
      await registerUser(formData); // Call backend function
      setSuccessMessage('Account successfully created!');

      navigate('/accountCreationPayment'); 
    } catch (error) {
      setErrorMessage(error.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container className="d-flex justify-content-center align-items-center min-vh-100">
      <div className="create-account-container">
        <h2 className="text-center mb-4">Create Account</h2>
        {successMessage && <Alert variant="success">{successMessage}</Alert>}
        {errorMessage && <Alert variant="danger">{errorMessage}</Alert>}
        <Form className="account-form" onSubmit={handleSubmit}>
          <Row>
            <Col md={6}>
              <Form.Group controlId="firstName">
                <Form.Label className="form-label">First Name</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter your first name"
                  onChange={handleInputChange}
                  isInvalid={formErrors.firstName}
                />
                <Form.Control.Feedback type="invalid">
                  {formErrors.firstName}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group controlId="lastName">
                <Form.Label className="form-label">Last Name</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter your last name"
                  onChange={handleInputChange}
                  isInvalid={formErrors.lastName}
                />
                <Form.Control.Feedback type="invalid">
                  {formErrors.lastName}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <Form.Group controlId="email">
                <Form.Label className="form-label">Email</Form.Label>
                <Form.Control
                  type="email"
                  placeholder="Enter your email"
                  onChange={handleInputChange}
                  isInvalid={formErrors.email}
                />
                <Form.Control.Feedback type="invalid">
                  {formErrors.email}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group controlId="password">
                <Form.Label className="form-label">Password</Form.Label>
                <Form.Control
                  type="password"
                  placeholder="Enter your password"
                  onChange={handleInputChange}
                  isInvalid={formErrors.password}
                />
                <Form.Control.Feedback type="invalid">
                  {formErrors.password}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <Form.Group controlId="address">
                <Form.Label className="form-label">Address</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter your address"
                  onChange={handleInputChange}
                  isInvalid={formErrors.address}
                />
                <Form.Control.Feedback type="invalid">
                  {formErrors.address}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group controlId="country">
                <Form.Label className="form-label">Country</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter your country"
                  onChange={handleInputChange}
                  isInvalid={formErrors.country}
                />
                <Form.Control.Feedback type="invalid">
                  {formErrors.country}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <Form.Group controlId="nationality">
                <Form.Label className="form-label">Nationality</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter your nationality"
                  onChange={handleInputChange}
                  isInvalid={formErrors.nationality}
                />
                <Form.Control.Feedback type="invalid">
                  {formErrors.nationality}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group controlId="investmentRange">
                <Form.Label className="form-label">Investment Range</Form.Label>
                <Form.Control
                  as="select"
                  onChange={handleInputChange}
                  isInvalid={formErrors.investmentRange}
                >
                  <option value="">Select Investment Range</option>
                  <option value="999 - 3000 $">999 - 3000 $</option>
                  <option value="3001 - 5000 $">3001 - 5000 $</option>
                  <option value="5001 - 10000 $">5001 - 10000 $</option>
                </Form.Control>
                <Form.Control.Feedback type="invalid">
                  {formErrors.investmentRange}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <Form.Group controlId="category">
                <Form.Label className="form-label">Category</Form.Label>
                <Form.Control
                  as="select"
                  onChange={handleInputChange}
                  isInvalid={formErrors.category}
                >
                  <option value="">Select Category</option>
                  <option value="Technology">Technology</option>
                  <option value="Finance">Finance</option>
                  <option value="Health">Health</option>
                </Form.Control>
                <Form.Control.Feedback type="invalid">
                  {formErrors.category}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group controlId="niche">
                <Form.Label className="form-label">Niche</Form.Label>
                <Form.Control
                  as="select"
                  onChange={handleInputChange}
                  isInvalid={formErrors.niche}
                >
                  <option value="">Select Niche</option>
                  <option value="AI">AI</option>
                  <option value="Blockchain">Blockchain</option>
                  <option value="Data Science">Data Science</option>
                </Form.Control>
                <Form.Control.Feedback type="invalid">
                  {formErrors.niche}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>
          <Form.Group  onClick={handleShow} controlId="termsCheckbox" className="mt-3 checkbox-group">
            <Form.Check className='me-5 pe-5' type="checkbox" label="Non-competition agreement with linked terms." />
          </Form.Group>
          <LinkedTermsPopUp show={showPopup} handleClose={handleClose} />
          <Button variant="primary" type="submit" className="submit-btn" disabled={loading}>
            {loading ? 'Processing...' : 'Sign up'}
          </Button>
          <div className="text-center mt-3">
            Already have an account? <a href="/register" className="signup-link">Login</a>
          </div>
        </Form>

        <style jsx>{`
          .signup-link {
            color: #c69e59;
            text-decoration: none;
            font-weight: bold;
          }
          .signup-link:hover {
            text-decoration: underline;
          }
          .create-account-container {
            width: 100%;
            max-width: 800px;
            padding: 2.5rem;
            background-color: #ffffff;
            border-radius: 15px;
            box-shadow: 0 6px 18px rgba(0, 0, 0, 0.1);
            margin-top: 20px;
            margin-bottom: 20px;
          }
          .account-form {
            margin-top: 1rem;
          }
          .form-label {
            display: block;
            font-weight: 600;
            margin-bottom: 0.3rem;
            color: #333;
            text-align: left;
          }
          .submit-btn {
            width: 30%;
            background-color: #c69e59;
            border: none;
            padding: 0.75rem;
            font-weight: bold;
            font-size: 1rem;
            margin-top: 1rem;
            margin-left: auto;
            margin-right: auto;
            display: block;
          }
          .submit-btn:hover {
            background-color: #b07e46;
          }
          .checkbox-group .form-check-input {
            margin-right: 0.5rem; /* Reduced space between checkbox and label */
          }
        `}</style>
      </div>
    </Container>
  );
}

export default Register;
