import { auth, db } from "../firebaseConfig";
import { signInWithEmailAndPassword } from "firebase/auth";
import { doc, getDoc } from "firebase/firestore";

/**
 * Logs in a user using Firebase Authentication and checks their role in Firestore.
 * @param {string} email - User's email.
 * @param {string} password - User's password.
 * @returns {Promise<string>} - Returns the user's role ("admin" or other).
 * @throws {Error} - Throws an error if login fails or user role is not found.
 */
export const loginUser = async (email, password) => {
  try {
    // Authenticate the user
    const userCredential = await signInWithEmailAndPassword(auth, email, password);
    const user = userCredential.user;

    // Fetch the user's role from Firestore
    const userDoc = doc(db, "users", user.uid);
    const userSnapshot = await getDoc(userDoc);

    if (userSnapshot.exists()) {
      const userData = userSnapshot.data();
      return userData.role; // Return the user's role
    } else {
      throw new Error("User role not found. Please contact support.");
    }
  } catch (error) {
    throw new Error("Invalid email or password. Please try again.");
  }
};
