import React, { useState, useEffect } from 'react';
import { Row, Col, Card, Spinner } from 'react-bootstrap';
import { BsPeople, BsBox, BsGraphUp } from 'react-icons/bs';
import { fetchCollectionData } from '../../services/retriveDataService'; // Update the import path as needed

const AdminPannelMainHero = () => {
    const [userCount, setUserCount] = useState(null); // Use null to signify loading state
    const [ideaCount, setIdeaCount] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const users = await fetchCollectionData('users');
                const ideas = await fetchCollectionData('ideas');

                setUserCount(users.length);
                setIdeaCount(ideas.length);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, []);

    const cardData = [
        {
            id: 1,
            title: "Total Users",
            count: userCount,
            icon: <BsPeople size={30} className="text-primary" />,
        },
        {
            id: 2,
            title: "Current Ideas",
            count: ideaCount,
            icon: <BsBox size={30} className="text-warning" />,
        },
        {
            id: 3,
            title: "Collaboration Applications",
            count: "100", // Static value as per original design
            icon: <BsGraphUp size={30} className="text-success" />,
        },
    ];

    return (
        <>
            <style>
                {`
                    .admin-pannel-hero-container {
                        padding: 20px;
                        margin-top: 10px;
                        border-radius: 10px;
                        padding-left: 250px;
                        padding-right: 40px;
                        width: 96%;
                    }

                    .admin-card {
                        border-radius: 10px;
                        box-shadow: 0px 5px 15px rgba(0, 91, 187, 0.3);
                        padding: 20px;
                        background-color: white;
                        width: 75%;
                        height: 140px;
                        margin: 0 auto;
                    }

                    .card-content {
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        height: 100%;
                    }

                    .count-and-title {
                        display: flex;
                        flex-direction: column;
                        text-align: left;
                        justify-content: center;
                    }

                    .count {
                        font-size: 24px;
                        font-weight: bold;
                        margin-bottom: 5px;
                    }

                    .title {
                        font-size: 14px;
                        color: #6c757d;
                    }

                    .icon-right {
                        margin-left: auto;
                        display: flex;
                        align-items: center;
                    }

                    /* Responsive Design */
                    @media (max-width: 768px) {
                        .admin-pannel-hero-container {
                            padding-left: 20px;
                            padding-right: 5px;
                        }
                        .admin-card {
                            width: 90%;
                        }
                    }
                `}
            </style>

            <div className="admin-pannel-hero-container">
                <Row>
                    {cardData.map((card) => (
                        <Col key={card.id} sm={12} md={6} lg={4} className="mb-4">
                            <Card className="admin-card">
                                <div className="card-content">
                                    <div className="count-and-title">
                                        <div className="count">
                                            {card.count === null ? ( // Show spinner if count is null
                                                <Spinner animation="border" size="sm" variant="primary" />
                                            ) : (
                                                card.count.toLocaleString()
                                            )}
                                        </div>
                                        <div className="title">{card.title}</div>
                                    </div>
                                    <div className="icon-right">{card.icon}</div>
                                </div>
                            </Card>
                        </Col>
                    ))}
                </Row>
            </div>
        </>
    );
};

export default AdminPannelMainHero;
