import React from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import './App.css';
import Footer from './components/footer';
import Home from './pages/HomePage';
import Login from './pages/auth/LoginPage';
import Register from './pages/auth/RegisterPage';
import DashboardHeader from './components/headerDashboard';
import UserDashboard from './pages/dashboard.js/UserDashboard';
import IdeaProposalPage from './pages/dashboard.js/IdeaPropsal';
import WritePitchPage from './pages/dashboard.js/WritePitch';
import PaymentConfirmationPage from './pages/dashboard.js/PaymentConfirmation';
import UserProfilePage from './pages/dashboard.js/UserProfile';
import AdminPannelHeader from './components/headerAdminPannel';
import AdminPannelSidebar from './components/sidebarAdminPannel';
import MainAdminPannelPage from './pages/admin_pannel/MainAdminPannel';
import AdminPannelUserManagement from './pages/admin_pannel/userManagment'
import AdminPannelUserProfilePage from './pages/admin_pannel/userProfile';
import IdeaSubmissionForm from './pages/admin_pannel/IdeaSubmissionForm';
import AdminPannelSettings from './pages/admin_pannel/Settings';
import AdminPannelIdeaManagement  from './pages/admin_pannel/IdeaManagment'
import EditIdeaSubmissionForm from './pages/admin_pannel/editIdea';
import AccountCreationPayment from './pages/auth/AccountCreationPayemnt';

const AppContent = () => {
  const location = useLocation();
  const isAdminRoute = location.pathname.startsWith('/admin'); // Identify admin routes

  return (
    <div className="App">
      {/* Render appropriate header */}
      {isAdminRoute ? <AdminPannelHeader /> : <DashboardHeader />}

      <div className="">
        {/* Render sidebar for admin routes */}
        {isAdminRoute && <AdminPannelSidebar />}

        {/* Main content */}
        <main
          className={isAdminRoute ? '' : ''}
          style={{ padding: isAdminRoute ? '20px' : '' }}
        >
          <Routes>
            {/* Public Routes */}
            <Route path="/" element={<Home />} />
            <Route path="/login" element={<Login />} />
            <Route path="/register" element={<Register />} />
            <Route path="/accountCreationPayment" element={<AccountCreationPayment />} />

            {/* Dashboard Routes */}
            <Route path="/dashboard" element={<UserDashboard />} />
            <Route path="/dashboard/ideaPropsal" element={<IdeaProposalPage />} />
            <Route path="/dashboard/writePitch" element={<WritePitchPage />} />
            <Route path="/dashboard/paymentConfirmation" element={<PaymentConfirmationPage />} />
            <Route path="/dashboard/userProfile" element={<UserProfilePage />} />

            {/* Admin Routes */}
            <Route path="/admin" element={<MainAdminPannelPage />} />
            <Route path="/admin/userManagment" element={<AdminPannelUserManagement />} />
            <Route path="/admin/userProfile" element={<AdminPannelUserProfilePage />} />
            <Route path="/admin/ideaSubmission" element={<IdeaSubmissionForm />} />
            <Route path="/admin/settings" element={<AdminPannelSettings />} />
            <Route path="/admin/ideaManagment" element={<AdminPannelIdeaManagement />} />
            <Route path="/admin/editIdeaForm" element={<EditIdeaSubmissionForm />} />
          </Routes>
        </main>
      </div>

      {/* Render footer */}
      {!isAdminRoute && <Footer />}
    </div>
  );
};


function App() {
  return (
    <Router>
      <AppContent />
    </Router>
  );
}

export default App;
