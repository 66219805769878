import { auth, db } from '../firebaseConfig';
import { createUserWithEmailAndPassword } from 'firebase/auth';
import { doc, setDoc } from 'firebase/firestore';

export const registerUser = async (formData) => {
  try {
    console.log('Attempting to register user with email:', formData.email);
    const userCredential = await createUserWithEmailAndPassword(
      auth,
      formData.email,
      formData.password
    );

    const user = userCredential.user;
    console.log('User authenticated:', user.uid);

    console.log('Storing user data in Firestore...');
    await setDoc(doc(db, 'users', user.uid), {
      ...formData,
      uid: user.uid,
      createdAt: new Date().toISOString(),
    });
    console.log('User data successfully stored in Firestore');

    return { success: true };
  } catch (error) {
   
    console.error('Error occurred:', error.message);
    throw new Error(error.message); 
  }
};
