import React from "react";
import { Modal } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";

const AccountCreationPopUp = ({ show, handleClose }) => {
  return (
    <>
      <style>
        {`
          .popup-container {
            text-align: center;
            padding: 20px;
            font-family: Arial, sans-serif;
          }
          .popup-header {
            font-size: 20px;
            font-weight: bold;
            margin-bottom: 20px;
          }
          .popup-icon {
            background-color: #28a745; /* Pure green background */
            border-radius: 50%;
            width: 100px;
            height: 100px;
            margin: 0 auto 20px auto;
            display: flex;
            justify-content: center;
            align-items: center;
          }
          .popup-icon i {
            font-size: 50px;
            color: white; /* White tick icon */
          }
          .popup-footer {
            font-size: 16px;
            color: #555;
            margin-top: 10px;
          }
        `}
      </style>

      <Modal show={show} onHide={handleClose} centered>
        <div className="popup-container">
          <div className="popup-header">
          Account Creation Successful 
          </div>
          <div className="popup-icon">
            <FontAwesomeIcon icon={faCheck} style={{ fontSize: "50px", color: "white" }} />
          </div>
          <div className="popup-footer">Congratulations!</div>
        </div>
      </Modal>
    </>
  );
};

export default AccountCreationPopUp;
