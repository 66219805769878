import React from "react";
import { Modal, Button } from "react-bootstrap";

const LinkedTermsPopUp = ({ show, handleClose }) => {
  return (
    <>
      <style>
        {`
          .popup-container {
            text-align: center;
            padding: 20px;
            font-family: Arial, sans-serif;
          }
          .popup-header {
            font-size: 20px;
            font-weight: bold;
            margin-bottom: 20px;
          }
          .popup-content {
            text-align: left;
            font-size: 16px;
          }
          .popup-section-title {
            font-weight: bold;
            margin-top: 20px;
            margin-bottom: 10px;
          }
          .popup-list {
            padding-left: 20px;
          }
          .confirm-btn {
            background-color: #f5b942;
            color: #fff;
            border: none;
            margin-top: 20px;
            padding: 10px 20px;
            border-radius: 5px;
            font-size: 16px;
          }
          .confirm-btn:hover {
            background-color: #e2a732;
          }
        `}
      </style>

      <Modal show={show} onHide={handleClose} centered>
        <div className="popup-container">
          <div className="popup-header">
          Terms & Condition
          </div>
          <div className="popup-content">
            <p>
              <strong>Business Concept:</strong> You’ve chosen to pursue a
              business focused on providing eco-friendly, biodegradable, and
              recyclable packaging solutions to businesses across various
              industries (food, retail, e-commerce). This concept targets the
              growing demand for sustainable packaging alternatives and
              positions you at the forefront of a rapidly expanding market.
            </p>
            <div className="popup-section-title">Profitability Estimate:</div>
            <ul className="popup-list">
              <li>Initial Revenue (Year 1): $200,000 – $350,000</li>
              <li>Growth Potential: Global market growth rate of 5–7% per year.</li>
              <li>Break-even: Expected within 12–18 months with strong market adoption.</li>
            </ul>
            <div className="popup-section-title">Initial Investment Breakdown:</div>
            <ul className="popup-list">
              <li>Production Facility Setup: $150,000 – $250,000</li>
              <li>Raw Materials & Inventory: $50,000 – $100,000</li>
              <li>Branding & Marketing: $20,000 – $50,000</li>
              <li>Technology & Equipment: $40,000 – $80,000</li>
              <li>Working Capital: $30,000</li>
            </ul>
          </div>
          <Button className="confirm-btn" onClick={handleClose}>
            Close
          </Button>
          
        </div>
      </Modal>
    </>
  );
};

export default LinkedTermsPopUp;
