import React from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';

const Slider = () => {
  return (
    <>
      <style>
        {`
          .slider-container {
            background-color: #f8f3e3;
            padding: 30px 0;
          }
        `}
      </style>
      <Container className="slider-container">
        <Row className="justify-content-center">
          <Col xs={12} md={8}>
            <Card>
              <Card.Img variant="top" src="/images/slider.png" height={'400'} />
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default Slider;
