import React from "react";
import CategorySettings from "../../components/admin-pannel/settingsCategory";
import NicheSettings from "../../components/admin-pannel/nicheSettingsCategory";

function AdminPannelSettings() {
  return (
    <div>
      <CategorySettings />
      <NicheSettings/>
    </div>
  );
}


export default AdminPannelSettings;
