import { fetchDocumentById } from '../services/retriveDataService'; 

export const fetchUserProfile = async (userId) => {
  try {
    const userData = await fetchDocumentById('users', userId);
    return userData;
  } catch (error) {
    console.error('Error fetching user profile:', error);
    throw error;
  }
};

