import React, { useState } from 'react';
import { Accordion, Container } from 'react-bootstrap';

const FrequentlyQuestions = () => {
  const [activeKey, setActiveKey] = useState(null);

  const handleToggle = (key) => {
    setActiveKey(activeKey === key ? null : key);
  };

  return (
    <>
      <style>
        {`
          .faq-container {
            margin-top: 3rem;
            margin-bottom: 3rem;
          }

          .faq-title {
            text-align: center;
            color: #c69e59; /* Updated FAQ title color */
            font-weight: bold;
            font-size: 1.75rem;
            margin-bottom: 1.5rem;
          }

          .faq-card {
            border: none;
            margin-bottom: 1rem;
            border-radius: 8px;
            box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
          }

          .faq-header {
            font-weight: bold;
            font-size: 1.1rem;
            padding: 1rem;
            cursor: pointer;
            border-radius: 8px;
            transition: background-color 0.3s ease, color 0.3s ease;
          }

          /* Closed state */
          .faq-header-closed {
            background-color: #f8f3e3; /* Soft light background when closed */
            color: #333; /* Dark text for better readability */
          }

          /* Open state */
          .faq-header-open {
            background-color: #d6d6d6; /* Subtle grey background when open */
            color: #333; /* Dark text when open */
          }

          /* Hover effect for all headers */
          .faq-header:hover {
            background-color: #e0e0e0; /* Lighter grey on hover */
            color: #333; /* Maintain dark text color */
          }

          /* Accordion body style */
          .faq-body {
            color: #666;
            font-size: 0.95rem;
            padding: 1.25rem;
            background-color: #fff;
            border-top: 1px solid #e0e0e0;
            border-bottom-left-radius: 8px;
            border-bottom-right-radius: 8px;
          }
        `}
      </style>

      <Container className="faq-container">
        <h2 className="faq-title">Frequently Asked Questions</h2>

        <Accordion activeKey={activeKey} onSelect={handleToggle}>
          <Accordion.Item eventKey="0" className="faq-card">
            <Accordion.Header
              className={`faq-header ${activeKey === '0' ? 'faq-header-open' : 'faq-header-closed'}`}
            >
              How does the Plug-And-Play Business Model work?
            </Accordion.Header>
            <Accordion.Body className="faq-body">
              Arcu at dictum sapien, mollis. Vulputate sit id accumsan, ultricies. In ultrices malesuada elit mauris.
            </Accordion.Body>
          </Accordion.Item>

          <Accordion.Item eventKey="1" className="faq-card">
            <Accordion.Header
              className={`faq-header ${activeKey === '1' ? 'faq-header-open' : 'faq-header-closed'}`}
            >
              What are the benefits of the Plug-And-Play Business Model?
            </Accordion.Header>
            <Accordion.Body className="faq-body">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
            </Accordion.Body>
          </Accordion.Item>

          <Accordion.Item eventKey="2" className="faq-card">
            <Accordion.Header
              className={`faq-header ${activeKey === '2' ? 'faq-header-open' : 'faq-header-closed'}`}
            >
              How can I get started with the Plug-And-Play Business Model?
            </Accordion.Header>
            <Accordion.Body className="faq-body">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </Container>
    </>
  );
};

export default FrequentlyQuestions;
